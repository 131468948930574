import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto'
const data = {
    labels: ['Overdue', 'Today', 'Tommorrow', 'Later', "Open"],
    datasets: [
      {
        label: '',
        data: [65, 59, 80, 100],
        backgroundColor: 
        ["aqua", "green", "red", "yellow", "black"],
    // Border color of each bar
    borderColor: ["aqua", "green", "red", "yellow", "black"],
        borderWidth: 1,
      },
    ],
  };

  const options={
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
    legend: {
        labels: {
            fontSize: 15,
        },
    },
}

const ProjectsChart = (props) => {

    const [chartData, setChartData] = useState(data)

    useEffect(() => {
        setChartData({
            labels: ['Overdue', 'Today', 'Tommorrow', 'Later'],
            datasets: [
              {
                label: '',
                data: [props?.data?.overdue, props?.data?.today, props?.data?.tomorrow, props?.data?.later],
                backgroundColor: 
                ["aqua", "green", "red", "yellow"],
            // Border color of each bar
            borderColor: ["aqua", "green", "red", "yellow"],
                borderWidth: 1,
              },
            ],
          })
    }, [props.data]);

  return <Bar data={chartData} options={options} />;
};

export default ProjectsChart;