const projectDependenciesActions = {
    GET_PROJECT_DEPENDENCIES: 'GET_PROJECT_DEPENDENCIES',
    SET_PROJECT_DEPENDENCIES: 'SET_PROJECT_DEPENDENCIES',
    CREATE_PROJECT_DEPENDENCY: 'CREATE_PROJECT_DEPENDENCY',
    UPDATE_PROJECT_DEPENDENCY: 'UPDATE_PROJECT_DEPENDENCY',
    DELETE_PROJECT_DEPENDENCY: 'DELETE_PROJECT_DEPENDENCY',
    SET_FILTER_PARAMS: 'SET_FILTER_PARAMS_PROJECT_DEPENDENCIES',
    SET_PAGINATION: 'SET_PAGINATION_PROJECT_DEPENDENCIES',
};

export default projectDependenciesActions;