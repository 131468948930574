import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import projectActions from "../redux/projects/actions";
import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from "@material-ui/core";
import * as Yup from 'yup';
import commonActions from "../redux/common/actions";
import Footer from "../components/common/footer";
import { DropdownIndicator } from "../utils/helpers";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { API_URL } from "../utils/constant";
import settingActions from "../redux/settings/actions";
import ReactSelect from "react-select";
import categoryActions from "../redux/category/actions";
import moment from 'moment';
const validationSchema = Yup.object().shape({
    client_id: Yup.object().typeError('Client is requried').required(),
    billable: Yup.boolean().required(),
    status: Yup.boolean().required(),
    category_id: Yup.string().typeError('Category is requried').required(),
    sub_category_id: Yup.string().typeError('SubCategory is requried').required(),
    title: Yup.string().required(),
    team_id: Yup.array().typeError('Team is requried').required(),
    team_lead_id: Yup.array().typeError('Team lead is requried').required(),
    admin_id: Yup.array().typeError('Admin is requried').required(),
    due_date: Yup.date().required('Due date is required')
        .min(Yup.ref('internal_due_date'), 'Due date must be after internal due date'),
    internal_due_date: Yup.date().required(),
});

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none"
    }),
};

const AddProject = () => {

    const dispatch = useDispatch();
    const [subCategories, setSubCategories] = useState([]);
    const [inputValue, setInputValue] = useState();
    const { settings } = useSelector(state => state.settingReducer);
    const { activeCategories } = useSelector(state => state.categoryReducer);
    const [subCatName, setSubCatName] = useState(null);
    const { projects, availableProjects } = useSelector(state => state.projectReducer);
    const { register, handleSubmit, reset, getValues, control, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const pageCount = useRef();

    const changeFilter = async (val) => {
        await axios.get(
            `${API_URL}/categories/${val}/sub-categories`,
        ).then((res) => {
            setSubCategories(res.data.subCategories);
        }
        ).catch(el => {
            setSubCategories([]);
        });
    }

    const onSubmit = (data) => {
        data['client_id'] = data.client_id.value;
        data['due_date'] = moment(data.due_date).format('YYYY-MM-DD');
        data['internal_due_date'] = moment(data.internal_due_date).format('YYYY-MM-DD');
        data["team_id"] = data.team_id.map(el => el.value)
        data["team_lead_id"] = data.team_lead_id.map(el => el.value)
        data["admin_id"] = data.admin_id.map(el => el.value)
        dispatch({
            type: projectActions.CREATE_PROJECT, payload: data
        });
    };

    const listAvailableClients = () => {
        dispatch({
            type: projectActions.GET_AVAILABLE_PROJECTS, payload: {
                // category_id: getValues()?.category_id,
                client_id: getValues()?.client_id?.value,
                // sub_category_id: getValues()?.sub_category_id,
            }
        });
    }

    useEffect(() => {
        dispatch({ type: settingActions.GET_SETTINGS });
        dispatch({ type: categoryActions.GET_ACTIVE_CATEGORIES });
    }, []);

    useEffect(() => {
        if (settings) {
            reset({
                'billable': settings?.new_project_billable
            });
        }
    }, [settings]);

    useEffect(() => {
        reset({
            'client_id': null,
            'category_id': null,
            'sub_category_id': null,
            'title': null,
            'team_id': null,
            'admin_id': null,
            'team_lead_id': null,
            'due_date': null,
            'internal_due_date': null,
            'billable': settings?.new_project_billable,
            'status': true
        });
        setSubCatName(null);
        dispatch({ type: projectActions.SET_AVAILABLE_PROJECTS, payload: [] })
    }, [projects]);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: projectActions.GET_PROJECTS });
    }, []);


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane active" id="custom-home" role="tabpanel">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Client</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    render={({ field }) => {
                                                                                        const currentSelection = field?.value
                                                                                        const handleAddProject = (selectedOption) => {
                                                                                            field.onChange({ value: selectedOption?.value, label: selectedOption?.name, name: selectedOption?.name, id: selectedOption?.value });
                                                                                            listAvailableClients();
                                                                                        };
                                                                                        return (
                                                                                            <AsyncSelect
                                                                                                styles={style}
                                                                                                {...field}
                                                                                                className="w-100"
                                                                                                inputRef={field.ref}
                                                                                                components={{ DropdownIndicator }}
                                                                                                name={"client_id"}
                                                                                                openMenuOnClick={false}
                                                                                                isClearable={true}
                                                                                                getOptionLabel={(e) => e?.name}
                                                                                                getOptionValue={(e) => e?.id}
                                                                                                onChange={handleAddProject}
                                                                                                loadOptions={debounce(async (inputValue, callback) => {
                                                                                                    if (inputValue.trim().length > 2) {
                                                                                                        const data = await axios.get(`${API_URL}/clients-search/${inputValue}`,
                                                                                                            {
                                                                                                                headers: {
                                                                                                                    "Content-Type": "application/json",
                                                                                                                },
                                                                                                            })
                                                                                                        callback(data.data.clients.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                                                                    } else {
                                                                                                        callback(null);
                                                                                                    }
                                                                                                }, 1000)}                                                                                            // onInputChange={(e) => handleSelectChange(e)}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                    name="client_id"
                                                                                />
                                                                                <Form.Control.Feedback type=" invalid">{errors.client_id?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Category</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    // {...register('ItemGroupCode')}
                                                                                    render={({ field }) => {
                                                                                        const currentSelection = activeCategories?.find(
                                                                                            (c) => c.id === field.value
                                                                                        );
                                                                                        const handleSelectChange = (selectedOption) => {
                                                                                            changeFilter(selectedOption?.value);
                                                                                            field.onChange(selectedOption?.value);
                                                                                        };
                                                                                        return (
                                                                                            <ReactSelect
                                                                                                {...field}
                                                                                                className="w-100"
                                                                                                inputRef={field.ref}
                                                                                                styles={style}
                                                                                                value={currentSelection ? { label: currentSelection.name, value: currentSelection.id } : null}
                                                                                                name={"category_id"}
                                                                                                options={activeCategories?.map(val => { return { ...val, label: val.name, value: val.id } })}
                                                                                                onChange={handleSelectChange}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                    name="category_id"
                                                                                />
                                                                                <Form.Control.Feedback type=" invalid">{errors.category_id?.message}</Form.Control.Feedback>                                                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">SubCategory</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    // {...register('ItemGroupCode')}
                                                                                    render={({ field }) => {

                                                                                        const currentSelection = subCategories.find(
                                                                                            (c) => c.id === field.value
                                                                                        );
                                                                                        const handleSelectChange = (selectedOption) => {
                                                                                            field.onChange(selectedOption?.value);
                                                                                            setSubCatName(selectedOption.label);
                                                                                        };
                                                                                        return (
                                                                                            <ReactSelect
                                                                                                {...field}
                                                                                                className="w-100"
                                                                                                inputRef={field.ref}
                                                                                                styles={style}
                                                                                                value={currentSelection ? { label: currentSelection.name, value: currentSelection.id } : null}
                                                                                                name={"sub_category_id"}
                                                                                                options={subCategories?.map(val => { return { ...val, label: val.name, value: val.id } })}
                                                                                                onChange={handleSelectChange}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                    name="sub_category_id"
                                                                                />
                                                                                <Form.Control.Feedback type=" invalid">{errors.sub_category_id?.message}</Form.Control.Feedback>                                                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 row">
                                                                            <div className="mb-3 col-md-6">
                                                                                <label className="form-label">Title</label>
                                                                                <Form.Control disabled={true} defaultValue={subCatName ?? ""} />
                                                                            </div>
                                                                            <div className="mb-3 col-md-6">
                                                                                <label className="form-label mt-3"></label>
                                                                                <Form.Control name="title" {...register('title')} isInvalid={!!errors.title} placeholder="Enter the suffix" />
                                                                                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Team</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    render={({ field }) => {

                                                                                        const handleInputChange = async (inputValue, { action }) => {
                                                                                            setInputValue(inputValue)
                                                                                        }

                                                                                        return (
                                                                                            <AsyncSelect
                                                                                                styles={style}
                                                                                                {...field}
                                                                                                className="w-100"
                                                                                                inputRef={field.ref}
                                                                                                components={{ DropdownIndicator }}
                                                                                                name={"team_id"}
                                                                                                isMulti
                                                                                                isClearable={true}
                                                                                                onInputChange={handleInputChange}
                                                                                                loadOptions={debounce(async (inputValue, callback) => {
                                                                                                    if (inputValue.trim().length > 2) {
                                                                                                        try {
                                                                                                            const response = await axios.get(`${API_URL}/teams-search/${inputValue}`, {
                                                                                                                headers: {
                                                                                                                    "Content-Type": "application/json",
                                                                                                                },
                                                                                                            });
                                                                                                            const data = response.data;
                                                                                                            callback(data.teams.map(el => ({ name: el.name, label: el.name, value: el.id })));
                                                                                                        } catch (error) {
                                                                                                            console.error("Error fetching teams:", error);
                                                                                                            callback([]);
                                                                                                        }
                                                                                                    } else {
                                                                                                        callback(null);
                                                                                                    }
                                                                                                }, 1000)}                                                                                           // onInputChange={(e) => handleSelectChange(e)}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                    name="team_id"
                                                                                />
                                                                                <Form.Control.Feedback type=" invalid">{errors.team_id?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">DueDate</label>
                                                                                <Form.Control name="due_date" type="date" {...register('due_date')} isInvalid={!!errors.due_date} />
                                                                                <Form.Control.Feedback type="invalid">{errors.due_date?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Internal DueDate</label>
                                                                                <Form.Control name="internal_due_date" type="date" {...register('internal_due_date')} isInvalid={!!errors.internal_due_date} />
                                                                                <Form.Control.Feedback type="invalid">{errors.internal_due_date?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Team lead</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    render={({ field }) => {
                                                                                        return (
                                                                                            <AsyncSelect
                                                                                                styles={style}
                                                                                                {...field}
                                                                                                className="w-100"
                                                                                                inputRef={field.ref}
                                                                                                components={{ DropdownIndicator }}
                                                                                                name={"team_lead_id"}
                                                                                                isMulti
                                                                                                openMenuOnClick={false}
                                                                                                isClearable={true}
                                                                                                getOptionLabel={(e) => e?.name}
                                                                                                getOptionValue={(e) => e?.id}
                                                                                                loadOptions={debounce(async (inputValue, callback) => {
                                                                                                    if (inputValue.trim().length > 2) {
                                                                                                        const data = await axios.get(`${API_URL}/team-lead-search/${inputValue}`,
                                                                                                            {
                                                                                                                headers: {
                                                                                                                    "Content-Type": "application/json",
                                                                                                                },
                                                                                                            })
                                                                                                        callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                                                                    } else {
                                                                                                        callback(null);
                                                                                                    }
                                                                                                }, 1000)}                                                                                            // onInputChange={(e) => handleSelectChange(e)}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                    name="team_lead_id"
                                                                                />
                                                                                <Form.Control.Feedback type=" invalid">{errors.team_lead_id?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Admin</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    render={({ field }) => {
                                                                                        return (
                                                                                            <AsyncSelect
                                                                                                styles={style}
                                                                                                {...field}
                                                                                                className="w-100"
                                                                                                inputRef={field.ref}
                                                                                                components={{ DropdownIndicator }}
                                                                                                name={"admin_id"}
                                                                                                isMulti
                                                                                                openMenuOnClick={false}
                                                                                                isClearable={true}
                                                                                                getOptionLabel={(e) => e?.name}
                                                                                                getOptionValue={(e) => e?.id}
                                                                                                loadOptions={debounce(async (inputValue, callback) => {
                                                                                                    if (inputValue.trim().length > 2) {
                                                                                                        const data = await axios.get(`${API_URL}/admin-search/${inputValue}`,
                                                                                                            {
                                                                                                                headers: {
                                                                                                                    "Content-Type": "application/json",
                                                                                                                },
                                                                                                            })
                                                                                                        callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                                                                    } else {
                                                                                                        callback(null);
                                                                                                    }
                                                                                                }, 1000)}                                                                                            // onInputChange={(e) => handleSelectChange(e)}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                    name="admin_id"
                                                                                />
                                                                                <Form.Control.Feedback type=" invalid">{errors.admin_id?.message}</Form.Control.Feedback>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <input type="checkbox" name="billable" id="billable" className="mr-2"  {...register('billable')} isInvalid={!!errors.billable} />
                                                                                <label className="form-label" htmlFor="billable">Billable</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <input type="checkbox" name="status" id="status" className="mr-2" defaultValue={settings?.status} {...register('status')} isInvalid={!!errors.status} />
                                                                                <label className="form-label" htmlFor="status">Active</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <Button className="btn btn-light me-1">
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button className="btn btn-success" type="submit">
                                                                                    Submit
                                                                                </Button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {availableProjects?.length > 0 &&
                                                        <div className="col-6">
                                                            <div className="card">
                                                                <div className="card-body">

                                                                    <div className="table-responsive mt-30">
                                                                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Code</th>
                                                                                    <th>Title</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {availableProjects?.map(el => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <th>{el.code}</th>
                                                                                            <th>{el.title}</th>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="tab-pane" id="custom-settings" role="tabpanel">
                                            Check the module and update the components
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div >
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default AddProject;