
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import Footer from "../components/common/footer";
import { Nav, Tab, TabContainer, TabContent, Pagination } from "react-bootstrap";
import Tasks from "./tasks";
import ProjectDependency from "./project-dependecy";
import ProjectHistories from "./project-history";
import ProjectApprovals from "./project-approval";

const ProjectDetail = () => {

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <TabContainer defaultActiveKey="tasks">
                                        <Nav className="nav-tabs nav-justified nav-tabs-custom" style={{ pointerEvents: 'visible' }}>
                                            <Nav.Item className="cursor-pointer">
                                                <Nav.Link eventKey="tasks">
                                                    <span className="d-md-inline-block">Tasks</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="cursor-pointer">
                                                <Nav.Link eventKey="projectDependencies">   <span className="d-md-inline-block">Project Dependency</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="cursor-pointer">
                                                <Nav.Link eventKey="project_histories">
                                                    <span className="d-md-inline-block">Project History</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <TabContent>
                                            <Tab.Pane eventKey="tasks">
                                                <Tasks />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="projectDependencies">
                                                <ProjectDependency />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="project_histories">
                                                <ProjectHistories />
                                            </Tab.Pane>
                                        </TabContent>
                                    </TabContainer>

                                </div>
                            </div>

                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default ProjectDetail;