import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Pagination } from "react-bootstrap";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import projectHistoryActions from "../redux/project-history/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import Footer from "../components/common/footer";
import { debounce } from "lodash";

const ProjectHistories = () => {

    const dispatch = useDispatch();
    const { project_histories, filterParams, pagination } = useSelector(state => state.projectHistoryReducer);


    const paginate = (page) => {
        dispatch({
            type: projectHistoryActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    useEffect(() => {
        dispatch({ type: projectHistoryActions.GET_PROJECT_HISTORIES });
    }, []); 

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: projectHistoryActions.GET_PROJECT_HISTORIES });
    }, [filterParams, pagination]); 

    return (
        <>
            <div className="tab-content p-3">
                <div className="tab-pane active" id="custom-home" role="tabpanel">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-4">
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-3">
                                </div>
                            </div>
                            <div className="table-responsive mt-30">
                                <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Type</th>
                                            <th>User</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {project_histories?.data?.map(el => {
                                            return (
                                                <tr>
                                                    <td width="300">
                                                        <h6>{el.description}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{el.type}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{el.user.name}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{el.action}</h6>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                        {project_histories?.data?.length === 0 && <tr key="no-data">
                                            <td></td>
                                            <td colSpan={2}>No Data Available</td>
                                            <td></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {project_histories?.data?.length &&
                                <Pagination>
                                    {<Pagination.First onClick={() => project_histories?.first_page_url ? paginate(1) : ""} />}
                                    {<Pagination.Prev onClick={() => project_histories?.prev_page_url ? paginate(project_histories?.current_page - 1) : ""} />}
                                    <Pagination.Item>{project_histories?.from} - {project_histories?.to} of {project_histories?.total}</Pagination.Item>
                                    {<Pagination.Next onClick={() => project_histories?.next_page_url ? paginate(project_histories?.current_page + 1) : ""} />}
                                    {<Pagination.Last onClick={() => project_histories?.last_page_url ? paginate(project_histories?.last_page) : ""} />}
                                </Pagination>
                            }
                        </div>
                    </div>
                </div>
                <div className="tab-pane" id="custom-settings" role="tabpanel">
                    Check the module and update the components
                </div>
            </div>
        </>
    )
}

export default ProjectHistories;

{/* <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
<thead>
    <tr>
        <th>Description</th>
        <th>Type</th>
        <th>User</th>
        <th>Action</th>
    </tr>
</thead>
<tbody>
    {project_histories?.data?.map(el => {
        return (
            <tr>
                <td width="300">
                    <h6>{el.description}</h6>
                </td>
                <td width="300">
                    <h6>{el.type}</h6>
                </td>
                <td width="300">
                    <h6>{el.user.name}</h6>
                </td>
                <td width="300">
                    <h6>{el.action}</h6>
                </td>
            </tr>
        )
    })
    }
    {project_histories?.data?.length === 0 && <tr key="no-data">
        <td></td>
        <td colSpan={2}>No Data Available</td>
        <td></td>
    </tr>}
</tbody>
</table> */}