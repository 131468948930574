import commonReducer from "./common/reducer";
import categoryReducer from "./category/reducer";
import authReducer from "./auth/reducer";
import teamReducer from "./team/reducer";
import subCategoryReducer from "./sub-category/reducer";
import settingReducer from "./settings/reducer";
import clientReducer from "./clients/reducer";
import projectSettingReducer from "./project-settings/reducer";
import projectReducer from "./projects/reducer";
import timeTrackReducer from "./timetrack/reducer";
import taskReducer from "./tasks/reducer";
import reportReducer from "./reports/reducer";
import projectDependencyReducer from "./project-dependency/reducer";
import projectHistoryReducer from "./project-history/reducer";
import projectApprovalReducer from "./project-approval/reducer";
import dashboardReducer from "./dashboard/reducer";

const reducers = {
    commonReducer,
    categoryReducer,
    authReducer,
    teamReducer,
    subCategoryReducer,
    settingReducer,
    clientReducer,
    projectSettingReducer,
    projectReducer,
    timeTrackReducer,
    taskReducer,
    reportReducer,
    projectDependencyReducer,
    projectHistoryReducer,
    projectApprovalReducer,
    dashboardReducer,
};

export default reducers;
