const dashboardActions = {
    GET_OWN_TASKS: 'GET_OWN_TASKS',
    SET_OWN_TASKS: 'SET_OWN_TASKS',
    GET_ASSIGNED_TASKS: 'GET_ASSIGNED_TASKS',
    SET_ASSIGNED_TASKS: 'SET_ASSIGNED_TASKS',
    GET_ASSIGNED_PROJECTS: 'GET_ASSIGNED_PROJECTS',
    SET_ASSIGNED_PROJECTS: 'SET_ASSIGNED_PROJECTS',
    GET_OWN_PROJECTS: 'GET_OWN_PROJECTS',
    SET_OWN_PROJECTS: 'SET_OWN_PROJECTS',
    SET_ASSIGNED_PROJECTS: 'SET_ASSIGNED_PROJECTS',
    GET_COLLAPSIBLE_TASKS_BY_NAME: 'GET_COLLAPSIBLE_TASKS_BY_NAME',
    SET_COLLAPSIBLE_TASKS_BY_NAME: 'SET_COLLAPSIBLE_TASKS_BY_NAME',
    GET_COLLAPSIBLE_PROJECTS_BY_NAME: 'GET_COLLAPSIBLE_PROJECTS_BY_NAME',
    SET_COLLAPSIBLE_PROJECTS_BY_NAME: 'SET_COLLAPSIBLE_PROJECTS_BY_NAME',
    GET_ASSIGNED_TASKS_BY_NAME: 'GET_ASSIGNED_TASKS_BY_NAME',
    SET_ASSIGNED_TASKS_BY_NAME: 'SET_ASSIGNED_TASKS_BY_NAME',
    GET_ASSIGNED_PROJECTS_BY_NAME: 'GET_ASSIGNED_PROJECTS_BY_NAME',
    SET_ASSIGNED_PROJECTS_BY_NAME: 'SET_ASSIGNED_PROJECTS_BY_NAME',
};

export default dashboardActions;