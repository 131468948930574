import { useEffect } from "react";
import moment from 'moment';
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import { useDispatch, useSelector } from "react-redux";
import dashboardActions from "../redux/dashboard/actions";
import AssignedTasksTable from "../components/dashboard/tasks/assigned_tasks_table";
import OwnTasksTable from "../components/dashboard/tasks/own_tasks_table";
import TasksChart from "../components/dashboard/tasks/tasks-chart";
import { API_URL } from "../utils/constant";
import { DropdownIndicator } from "../utils/helpers";
import AsyncSelect from 'react-select/async';
import { debounce } from "@material-ui/core";
import axios from "axios";
import AssignedTasksByNameTable from "../components/dashboard/tasks/assigned_tasks_by_name_table";
import ProjectsChart from "../components/dashboard/projects/projects-chart";
import OwnProjectsTable from "../components/dashboard/projects/own_projects_table";
import AssignedProjectsTable from "../components/dashboard/projects/assigned_projects_table";
import CollapsibleTable from "../components/dashboard/tasks/collapsible_task_table";
import CollapsibleProjectTable from "../components/dashboard/projects/collapsible_project_table";

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none"
    }),
};

const Dashboard = () => {

    const { assigned_tasks, assigned_tasks_by_name, own_tasks, collapsible_projects_by_name, assigned_projects, collapsible_tasks_by_name } = useSelector(state => state.dashboardReducer);

    const dispatch = useDispatch();

    const handleUserTasks = (e) => {
        if (!!e) {
            dispatch({
                type: dashboardActions.GET_ASSIGNED_TASKS_BY_NAME, payload: {
                    name: e.name
                }
            })
        } else {
            dispatch({
                type: dashboardActions.SET_ASSIGNED_TASKS_BY_NAME, payload: []
            })
        }
    }

    const handleCollasibleUserTasks = (e) => {
        if (!!e) {
            dispatch({
                type: dashboardActions.GET_COLLAPSIBLE_TASKS_BY_NAME, payload: {
                    name: e.name
                }
            })
        } else {
            dispatch({
                type: dashboardActions.SET_COLLAPSIBLE_TASKS_BY_NAME, payload: []
            })
        }
    }

    const handleCollasibleUserProjectTasks = (e) => {
        if (!!e) {
            dispatch({
                type: dashboardActions.GET_COLLAPSIBLE_PROJECTS_BY_NAME, payload: {
                    name: e.name
                }
            })
        } else {
            dispatch({
                type: dashboardActions.SET_COLLAPSIBLE_TASKS_BY_NAME, payload: []
            })
        }
    }

    useEffect(() => {
        dispatch({ type: dashboardActions.GET_OWN_TASKS });
        dispatch({ type: dashboardActions.GET_OWN_PROJECTS });
        dispatch({ type: dashboardActions.GET_ASSIGNED_TASKS });
        dispatch({ type: dashboardActions.GET_ASSIGNED_PROJECTS });
    }, []);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="text-center">Tasks handled by you</h5>
                                            <div className="table-responsive mt-30">
                                                <TasksChart data={own_tasks} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {(localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('team_lead'))
                                    &&
                                    <div className="col-lg-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="text-center">Tasks handled by your reportees</h5>
                                                <div className="table-responsive mt-30">
                                                    <AssignedTasksTable data={assigned_tasks?.users ? assigned_tasks?.users : []} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {(localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('team_lead'))
                                &&
                                <>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="text-center">Projects Headed by you</h5>
                                                    <div className="table-responsive mt-30">
                                                        <ProjectsChart data={assigned_projects ? assigned_projects : null} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="text-center">Projects Headed by your Reportees</h5>
                                                    <div className="table-responsive mt-30">
                                                        <AssignedProjectsTable data={assigned_projects?.users ? assigned_projects?.users : []} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-lg-12">
                                                    <h5 className="text-center">List of Tasks Handled (Based on Task Duedate) </h5>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="d-flex mr-3">
                                                        <h5 className="text-center">Tasks Handled by </h5>
                                                    </div>
                                                    <div>
                                                        <AsyncSelect
                                                            styles={style}
                                                            isClearable={true}
                                                            className="w-100"
                                                            components={{ DropdownIndicator }}
                                                            name={"user"}
                                                            openMenuOnClick={false}
                                                            onChange={handleUserTasks}
                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                if (inputValue.trim().length > 2) {
                                                                    const data = await axios.get(`${API_URL}/users-search/${inputValue}`,
                                                                        {
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                        })
                                                                    callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                                } else {
                                                                    callback(null);
                                                                }
                                                            }, 1000)}                                                                                            // onInputChange={(e) => handleSelectChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Overdue({assigned_tasks_by_name.filter(el => moment(el.task_due_date).isBefore(moment(new Date()))).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <AssignedTasksByNameTable type="overdue" data={assigned_tasks_by_name ? assigned_tasks_by_name.filter(el => moment(el.task_due_date).isBefore(moment(new Date()))) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Today({assigned_tasks_by_name.filter(el => moment(el.task_due_date).isSame(moment(new Date()))).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <AssignedTasksByNameTable type="Today" data={assigned_tasks_by_name ? assigned_tasks_by_name.filter(el => moment(el.task_due_date).isSame(moment(new Date()))) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Tommorrow({assigned_tasks_by_name.filter(el => moment(el.task_due_date).isSame(moment().add(1, 'days').startOf('day'), 'day')).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <AssignedTasksByNameTable type="Tommorrow" data={assigned_tasks_by_name ? assigned_tasks_by_name.filter(el => moment(el.task_due_date).isSame(moment().add(1, 'days').startOf('day'), 'day')) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Later({assigned_tasks_by_name.filter(el => moment(el.task_due_date).isBefore(moment().add(1, 'days').startOf('day'), 'day')).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <AssignedTasksByNameTable type="Later" data={assigned_tasks_by_name ? assigned_tasks_by_name.filter(el => moment(el.task_due_date).isBefore(moment().add(1, 'days').startOf('day'), 'day')) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-lg-12">
                                                    <h5 className="text-center">List of Tasks Handled (Based on Project Duedate) </h5>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="d-flex mr-3">
                                                        <h5 className="text-center">Tasks Handled by </h5>
                                                    </div>
                                                    <AsyncSelect
                                                        styles={style}
                                                        isClearable={true}
                                                        className="w-100"
                                                        components={{ DropdownIndicator }}
                                                        name={"user"}
                                                        openMenuOnClick={false}
                                                        onChange={handleCollasibleUserTasks}
                                                        loadOptions={debounce(async (inputValue, callback) => {
                                                            if (inputValue.trim().length > 2) {
                                                                const data = await axios.get(`${API_URL}/users-search/${inputValue}`,
                                                                    {
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                        },
                                                                    })
                                                                callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                            } else {
                                                                callback(null);
                                                            }
                                                        }, 1000)}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Overdue({collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isBefore(moment(new Date()))).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <CollapsibleTable type="CollapsibleOverdue" data={collapsible_tasks_by_name ? collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isBefore(moment(new Date()))) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Today({collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isSame(moment(new Date()))).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <CollapsibleTable type="CollapsibleToday" data={collapsible_tasks_by_name ? collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isSame(moment(new Date()))) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Tomorrow({collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isSame(moment().add(1, 'days').startOf('day'), 'day')).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <CollapsibleTable type="CollapsibleTomorrow" data={collapsible_tasks_by_name ? collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isSame(moment().add(1, 'days').startOf('day'), 'day')) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="text-center">Later({collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isBefore(moment().add(1, 'days').startOf('day'), 'day')).length})</h5>
                                                            <div className="table-responsive mt-30">
                                                                <CollapsibleTable type="CollapsibleLater" data={collapsible_tasks_by_name ? collapsible_tasks_by_name.filter(el => moment(el.internal_due_date).isBefore(moment().add(1, 'days').startOf('day'), 'day')) : []} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(localStorage.getItem('ksp_roles')?.includes('admin'))
                                        &&
                                        <div className="row">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="col-lg-12">
                                                        <h5 className="text-center">List of Projects Headed </h5>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="d-flex mr-3">
                                                            <h5 className="text-center">Projects Headed by </h5>
                                                        </div>
                                                        <AsyncSelect
                                                            styles={style}
                                                            isClearable={true}
                                                            className="w-100"
                                                            components={{ DropdownIndicator }}
                                                            name={"user"}
                                                            openMenuOnClick={false}
                                                            onChange={handleCollasibleUserProjectTasks}
                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                if (inputValue.trim().length > 2) {
                                                                    const data = await axios.get(`${API_URL}/users-search/${inputValue}`,
                                                                        {
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                        })
                                                                    callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                                } else {
                                                                    callback(null);
                                                                }
                                                            }, 1000)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="text-center">Overdue({collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isBefore(moment(new Date()))).length})</h5>
                                                                <div className="table-responsive mt-30">
                                                                    <CollapsibleProjectTable type="ProjectCollapsibleOverdue" data={collapsible_projects_by_name ? collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isBefore(moment(new Date()))) : []} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="text-center">Today({collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isSame(moment(new Date()))).length})</h5>
                                                                <div className="table-responsive mt-30">
                                                                    <CollapsibleProjectTable type="ProjectCollapsibleToday" data={collapsible_projects_by_name ? collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isSame(moment(new Date()))) : []} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="text-center">Tomorrow({collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isSame(moment().add(1, 'days').startOf('day'), 'day')).length})</h5>
                                                                <div className="table-responsive mt-30">
                                                                    <CollapsibleProjectTable type="ProjectCollapsibleTomorrow" data={collapsible_projects_by_name ? collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isSame(moment().add(1, 'days').startOf('day'), 'day')) : []} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="text-center">Later({collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isBefore(moment().add(1, 'days').startOf('day'), 'day')).length})</h5>
                                                                <div className="table-responsive mt-30">
                                                                    <CollapsibleProjectTable type="ProjectCollapsibleLater" data={collapsible_projects_by_name ? collapsible_projects_by_name.filter(el => moment(el.internal_due_date).isBefore(moment().add(1, 'days').startOf('day'), 'day')) : []} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                        </div>
                    </div>
                    <Footer />
                </div>
            </div >
            <div className="rightbar-overlay"></div>

        </>
    )
}

export default Dashboard;