import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const dashboardSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_OWN_TASKS, getOwnTasks),
        yield takeEvery(actions.GET_OWN_PROJECTS, getOwnProjects),
        yield takeEvery(actions.GET_ASSIGNED_TASKS, getAssignedTasks),
        yield takeEvery(actions.GET_ASSIGNED_PROJECTS, getAssignedProjects),
        yield takeEvery(actions.GET_ASSIGNED_TASKS_BY_NAME, getAssignedTasksByName),
        yield takeEvery(actions.GET_COLLAPSIBLE_TASKS_BY_NAME, getCollapsibleTasksByName),
        yield takeEvery(actions.GET_COLLAPSIBLE_PROJECTS_BY_NAME, getCollapsibleProjectsByName),
        yield takeEvery(actions.GET_ASSIGNED_PROJECTS_BY_NAME, getAssignedProjectsByName),
    ]);
};

const getOwnTasks = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/self-task-details`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_OWN_TASKS, payload: result?.data ? {
                ...result.data, "tasks": [
                    
                    ...result.data.tasks.map(el => {
                    return {
                        "id": el.id,
                        "name": el.name,
                        "project": el.project,
                        "status": el.status,
                    }
                })
            
            ]
            } : null
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_OWN_TASKS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getOwnProjects = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/self-project-details`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_OWN_PROJECTS, payload: result?.data ? {
                ...result.data, "projects": result.data.projects.map(el => {
                    return {
                        "id": el.id,
                        "name": el.name,
                        "status": el.status,
                    }
                })
            } : null
        }); yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_OWN_PROJECTS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const getAssignedTasks = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/task-details`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_ASSIGNED_TASKS, payload: result?.data ? {
                ...result.data, "users": result.data.users.map(el => {
                    return {
                        "id": el.id,
                        "name": el.name,
                        "overdue": el.overdue,
                        "today": el.today,
                        "tomorrow": el.tomorrow,
                        "later": el.later,
                        "open": el.open,
                    }
                })
            } : null
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.SET_ASSIGNED_TASKS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getAssignedTasksByName = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/task-details/${data.payload.name}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_ASSIGNED_TASKS_BY_NAME, payload: result.data.tasks.map(el => {
                return {
                    name: el.name,
                    task_due_date: el.task_due_date,
                    project: el.project,
                    project_od_days: el.project_od_days,
                    internal_due_date: el.name,
                    task_od_days: el.task_od_days,
                }
            })
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_ASSIGNED_TASKS_BY_NAME, payload: [] });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getCollapsibleTasksByName = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/project-details/${data.payload.name}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_COLLAPSIBLE_TASKS_BY_NAME, payload: result.data.projects
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_COLLAPSIBLE_TASKS_BY_NAME, payload: [] });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getCollapsibleProjectsByName = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/project-task-details/${data.payload.name}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_COLLAPSIBLE_PROJECTS_BY_NAME, payload: result.data.projects
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_COLLAPSIBLE_PROJECTS_BY_NAME, payload: [] });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getAssignedProjects = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/project-details`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({
            type: actions.SET_ASSIGNED_PROJECTS, payload: {...result?.data,
            "users": [
                ...result.data.users,
            ]
            }
        });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_ASSIGNED_PROJECTS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}

const getAssignedProjectsByName = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/project-details/${data.payload.name}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({ type: actions.SET_ASSIGNED_PROJECTS_BY_NAME, payload: result?.data ? result.data.groupedProjects : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_ASSIGNED_PROJECTS_BY_NAME, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
export default dashboardSaga;