import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import projectDependenciesActions from "../redux/project-dependency/actions";
import Button from 'react-bootstrap/Button';
import { Form, Dropdown, Pagination } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import { debounce } from "lodash";
import moment from "moment/moment";
const validationSchema = Yup.object().shape({
    short_description: Yup.string().required(),
    long_description: Yup.string(),
    closure_date: Yup.date().required(),
    closure: Yup.bool().required(),
});

const ProjectDependency = () => {

    const dispatch = useDispatch();
    const [modalDetails, setModalDetails] = useState(false);
    // const [modalDeleteDetails, setModalDeleteDetails] = useState(false);
    const { projectDependencies, filterParams, pagination } = useSelector(state => state.projectDependencyReducer);
    const form = useRef();
    const projectDependencyName = useRef();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const projectId = window.location.pathname.split('/')[2];

    const handleClose = () => {
        setModalDetails(null);
        // setModalDeleteDetails(null);
        reset({
            'short_description': '',
            'long_description': '',
            'closure_date': moment(new Date()).format('YYYY-MM-DD'),
            'closure': false
        })
    }

    const handleAddOpen = () => {
        setModalDetails(true);
    }

    const handleEditOpen = (e, b) => {
        if (e === "1") {
            reset({
                short_description: b.short_description,
                long_description: b.long_description,
                closure_date: moment(b.closure_date).format('YYYY-MM-DD'),
                closure: !!b.closure
            });
            setModalDetails(b);
        } else {
            // setModalDeleteDetails(b);
            dispatch({
                type: projectDependenciesActions.DELETE_PROJECT_DEPENDENCY, payload: { id: b?.id }
            });
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    const onSubmit = (data) => {
        if (!!modalDetails?.short_description) {
            data['id'] = modalDetails?.id;
            data['project_id'] = projectId;
            data['closure'] = !!data.closure;
            data['closure_date'] = moment(data.closure_date).format('YYYY-MM-DD');
            dispatch({
                type: projectDependenciesActions.UPDATE_PROJECT_DEPENDENCY, payload: data,
            });
        } else {
            data['project_id'] = projectId;
            data['closure'] = !!data.closure;
            data['closure_date'] = moment(data.closure_date).format('YYYY-MM-DD');
            dispatch({
                type: projectDependenciesActions.CREATE_PROJECT_DEPENDENCY, payload: data
            });
        }
    };

    const paginate = (page) => {
        dispatch({
            type: projectDependenciesActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: projectDependenciesActions.SET_FILTER_PARAMS, payload: {
                name: projectDependencyName.current.value,
            }
        });
        dispatch({
            type: projectDependenciesActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: projectDependenciesActions.SET_FILTER_PARAMS, payload: {
                name: e.target.value
            }
        });
        dispatch({
            type: projectDependenciesActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: 1
            }
        });
    }

    const debouncedChangeHandler = useCallback(
        debounce(handleName, 400)
        , []);

    useEffect(() => {
        handleClose();
    }, [projectDependencies]);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: projectDependenciesActions.GET_PROJECT_DEPENDENCIES });
    }, [filterParams, pagination]);

    return (
        <>
            <div className="tab-content p-3">
                <div className="tab-pane active" id="custom-home" role="tabpanel">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form className="app-search client-search">
                                                <div className="position-relative">
                                                    <input type="text" onChange={debouncedChangeHandler} className="form-control" ref={projectDependencyName} placeholder="Search by name" />
                                                    <span className="ri-search-line"></span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" data-bs-toggle="modal" onClick={handleAddOpen}>Add Project Dependency</button>
                                </div>
                            </div>
                            <div className="table-responsive mt-30">
                                <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Short Description</th>
                                            <th>Long Description</th>
                                            <th>Closure Date</th>
                                            <th>Closure</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectDependencies?.data?.map(el => {
                                            return (
                                                <tr>
                                                    <td width="300">
                                                        <h6>{el.short_description}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{el.long_description}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{moment(el.closure_date).format('YYYY-MM-DD')}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{!!el.closure ? 'Closed' : 'Pending'}</h6>
                                                    </td>
                                                    <td width="50">
                                                        <Dropdown onSelect={(e) => handleEditOpen(e, el)}>
                                                            <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                <div className="btn-group">
                                                                    <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></button>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu align={'end'} >
                                                                        <Dropdown.Item eventKey="1">Edit Project Dependency</Dropdown.Item>
                                                                        <Dropdown.Item eventKey="2">Delete Project Dependency</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </div>
                                                            </div>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                        {projectDependencies?.data?.length === 0 && <tr key="no-data">
                                            <td></td>
                                            <td colSpan={2}>No Data Available</td>
                                            <td></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {projectDependencies?.data?.length &&
                                <Pagination>
                                    {<Pagination.First onClick={() => projectDependencies?.first_page_url ? paginate(1) : ""} />}
                                    {<Pagination.Prev onClick={() => projectDependencies?.prev_page_url ? paginate(projectDependencies?.current_page - 1) : ""} />}
                                    <Pagination.Item>{projectDependencies?.from} - {projectDependencies?.to} of {projectDependencies?.total}</Pagination.Item>
                                    {<Pagination.Next onClick={() => projectDependencies?.next_page_url ? paginate(projectDependencies?.current_page + 1) : ""} />}
                                    {<Pagination.Last onClick={() => projectDependencies?.last_page_url ? paginate(projectDependencies?.last_page) : ""} />}
                                </Pagination>
                            }
                        </div>
                        <Modal show={!!modalDetails} onHide={handleClose}>
                            <Modal.Header className="py-3 px-4" closeButton>
                                <Modal.Title className="h5">{modalDetails?.short_description ? "Update Project Dependency" : "Create Project Dependency"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row"></div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Short description</label>
                                            <Form.Control name="short_description" {...register('short_description')} isInvalid={!!errors.short_description} placeholder="Enter the short description" />
                                            <Form.Control.Feedback type="invalid">{errors.short_description?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        <div className="mb-3">
                                            <label className="form-label">Long description</label>
                                            <Form.Control name="long_description" {...register('long_description')} isInvalid={!!errors.long_description} placeholder="Enter the long descrition" />
                                            <Form.Control.Feedback type="invalid">{errors.long_description?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        <div className="mb-3">
                                            <label className="form-label">Closure Date</label>
                                            <Form.Control name="closure_date" type="date" {...register('closure_date')} isInvalid={!!errors.closure_date} placeholder="Enter the Closure date" />
                                            <Form.Control.Feedback type="invalid">{errors.closure_date?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        <div className="mb-3">
                                            <label className="form-label">Closure</label>
                                            <select className="form-select form-control" name="closure" {...register("closure")}>
                                                <option value={true}>Closed</option>
                                                <option value={false}>Pending</option>
                                            </select>
                                        </div>
                                        <hr />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="text-end">
                                <Button className="btn btn-light me-1" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button className="btn btn-success" onClick={formSubmit}>
                                    {modalDetails?.short_description ? 'Update' : 'Create'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                <div className="tab-pane" id="custom-settings" role="tabpanel">
                    Check the module and update the components
                </div>
            </div>
        </>
    )
}

export default ProjectDependency;