import actions from "./actions";

const initialState = {
    projectDependencies: null,
    filterParams: {
        status: true,
        name: ""
    },
    pagination: {
        entries: 5,
        currentPage: 1
    }
};
const projectDependencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PROJECT_DEPENDENCIES:
            return {
                ...state,
                projectDependencies: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default projectDependencyReducer;