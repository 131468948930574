import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import projectActions from "../redux/projects/actions";
import Button from 'react-bootstrap/Button';
import { Form, Dropdown, Pagination } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import Footer from "../components/common/footer";
import { debounce } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Client from "../components/projects/client";
import { Popover } from "antd";
import AsyncSelect from 'react-select/async';
import ExportProject from "../components/reports/export-project";
import { DropdownIndicator } from "../utils/helpers";
import axios from "axios";
import { API_URL } from "../utils/constant";

const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    status: Yup.boolean().required(),
    closure: Yup.boolean().required(),
    team_id: Yup.array().typeError('Team is requried').required(),
    team_lead_id: Yup.array().typeError('Team lead is requried').required(),
    admin_id: Yup.array().typeError('Admin is requried').required(),
    due_date: Yup.date().required('Due date is required'),
    internal_due_date: Yup.date().required('Internal Due date is required')
    .max(Yup.ref('due_date'), 'Internal Due date must be less than due date'),
});

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none"
    }),
};

const Projects = () => {

    const dispatch = useDispatch();
    const [modalDetails, setModalDetails] = useState(false);
    // const [modalDeleteDetails, setModalDeleteDetails] = useState(false);
    const [otherDetails, setOtherDetails] = useState(false);
    const [multiselects, setMultiSelects] = useState({})
    const { projects, filterParams, pagination, clientsSettings } = useSelector(state => state.projectReducer);
    const projectSelect = useRef();
    const billingSelect = useRef();
    const projectName = useRef();
    const form = useRef();
    const pageCount = useRef();
    const { register, handleSubmit, reset, resetField, control, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const handleClose = () => {
        setModalDetails(null);
        setMultiSelects({});
        // setModalDeleteDetails(null);
        setOtherDetails(null);
        reset({
            'title': '',
            'status': true,
            'billable': true,
            'closure': false,
            team_id: [],
            admin_id: [],
            team_lead_id: []
        })
    };

    const handleOtherDetailsClose = () => {
        setOtherDetails(null);
    }

    const handleOtherDetails = (el) => {
        setOtherDetails(el);
    }

    const handleEditOpen = (e, b) => {
        setMultiSelects({
            "team_id": [
                ...(b.teams?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
            ],
            "team_lead_id": [
                ...(b.team_leads?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
            ],
            "admin_id": [
                ...(b.admins?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
            ],
        });
        if (e === "1") {
            reset({
                title: b.title,
                status: !!b.status,
                closure: !!b.closure,
                due_date: moment(b.due_date).format('YYYY-MM-DD'),
                internal_due_date: moment(b.internal_due_date).format('YYYY-MM-DD'),
                billable: !!b.billable,
                'team_id': [
                    ...(b.teams?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
                ],
                'team_lead_id': [
                    ...(b.team_leads?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
                ],
                'admin_id': [
                    ...(b.admins?.map(val => { return { ...val, label: val.name, value: val.id, id: val?.id } })),
                ],
            });
            setModalDetails(b);
        } else {
            // setModalDeleteDetails(b);
            dispatch({
                type: projectActions.DELETE_PROJECT, payload: { id: b?.id }
            });
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    const onSubmit = (data) => {
        if (!!modalDetails?.title) {
            data['id'] = modalDetails?.id;
            data['client_id'] = modalDetails?.client_id;
            data['category_id'] = modalDetails?.category_id;
            data['sub_category_id'] = modalDetails?.sub_category_id;
            data['team_id'] = multiselects.team_id.map(el => el.id);
            data['team_lead_id'] = multiselects.team_lead_id.map(el => el.value);
            data['admin_id'] = multiselects.admin_id.map(el => el.value);
            data['due_date'] = moment(data?.due_date).format('YYYY-MM-DD');
            data['internal_due_date'] = moment(data?.internal_due_date).format('YYYY-MM-DD');
            data['billable'] = data['billable'] === "true";
            dispatch({
                type: projectActions.UPDATE_PROJECT, payload: data
            });
        } else {
            data['billable'] = data['billable'] === "true";
            dispatch({
                type: projectActions.CREATE_PROJECT, payload: data
            });
        }
        setModalDetails(null);
        setMultiSelects({});
    };

    const paginate = (page) => {
        dispatch({
            type: projectActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: page
            }
        });
    }

    const handleBillingStatus = (e) => {
        dispatch({
            type: projectActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                name: projectName.current.value,
                billing: e.target.value,
                status: projectSelect.current.value,
            }
        });
        dispatch({
            type: projectActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: projectActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                name: projectName.current.value,
                billing: billingSelect.current.value,
                status: e.target.value
            }
        });
        dispatch({
            type: projectActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: projectActions.SET_FILTER_PARAMS, payload: {
                ...filterParams,
                status: projectSelect.current.value,
                billing: billingSelect.current.value,
                name: e.target.value
            }
        });
        dispatch({
            type: projectActions.SET_PAGINATION, payload: {
                entries: pageCount.current.value,
                currentPage: 1
            }
        });
    }

    const perPage = (e) => {
        dispatch({
            type: projectActions.SET_PAGINATION, payload: {
                currentPage: 1,
                entries: e.target.value
            }
        });
    }

    useEffect(() => {
        handleClose();
    }, [projects]);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: projectActions.GET_PROJECTS });
    }, [filterParams, pagination, clientsSettings]);

    const debouncedChangeHandler = useCallback(
        debounce(handleName, 400)
        , []);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane active" id="custom-home" role="tabpanel">
                                            <div className="card">
                                                <div className="card-body">
                                                    {(localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('team_lead')) &&
                                                        <div className="row d-flex justify-content-end">
                                                            <div className="col-md-3">
                                                                <Link
                                                                    to={"/create-project"}
                                                                >
                                                                    <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" data-bs-toggle="modal">Add New Project</button>
                                                                </Link>
                                                            </div>
                                                        </div>}
                                                    <div className="row mt-3">
                                                        <div className="col-md-7">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <select className="form-select" ref={projectSelect} defaultValue={filterParams?.status} onChange={handleStatus}>
                                                                        <option value={true}>Show Active</option>
                                                                        <option value={false}>Show Archive</option>
                                                                        <option value={"all"}>Show All</option>
                                                                        <option value={"all_projects"}>Show All Projects</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <select className="form-select" ref={billingSelect} defaultValue={filterParams?.billing} onChange={handleBillingStatus}>
                                                                        <option value={'all'}>Billing/Unbilling</option>
                                                                        <option value={true}>Billing</option>
                                                                        <option value={false}>Unbilling</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Popover
                                                                        content={<Client />}
                                                                        title="Client"
                                                                        trigger="click"
                                                                    >
                                                                        <span>Client  <i className="fa fa-caret-down"></i></span>
                                                                    </Popover>
                                                                </div>
                                                                <div class="col-md-3 cursor-pointer">
                                                                    <Popover
                                                                        content={<ExportProject />}
                                                                        title="Export"
                                                                        trigger="click"
                                                                    >
                                                                        <span>Export  <i className="fa fa-caret-down"></i></span>
                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <form className="app-search client-search">
                                                                <div className="position-relative">
                                                                    <input type="text" onChange={debouncedChangeHandler} defaultValue={filterParams?.name} className="form-control" ref={projectName} placeholder="Search by name" />
                                                                    <span className="ri-search-line"></span>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-30">
                                                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Client</th>
                                                                    <th>Tracked</th>
                                                                    <th>Amount</th>
                                                                    <th>Access</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {projects?.data?.map(el => {
                                                                    return (
                                                                        <tr key={'projects-data' + el.id}>
                                                                            <td>
                                                                                <h6><Link to={"/projects/" + el.id + "/detail"}>{el.code}{el.title}</Link><p className="cursor-pointer" onClick={() => handleOtherDetails(el)}>+</p></h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{el.client.name}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{el.billableHours}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{el.billableAmount}</h6>
                                                                            </td>
                                                                            <td>
                                                                                {el.teams.map(e => <h6 key={'el-teams-name' + e.name}>{e.name}</h6>)}
                                                                            </td>
                                                                            <td>
                                                                                <Dropdown onSelect={(e) => handleEditOpen(e, el)}>
                                                                                    <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                        <div className="btn-group">
                                                                                            <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                                                <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                                                            </Dropdown.Toggle>
                                                                                            {(localStorage.getItem('ksp_roles').includes('super_admin') || localStorage.getItem('ksp_roles').includes('admin') || localStorage.getItem('ksp_roles').includes('team_lead')) &&
                                                                                                <><Dropdown.Menu align={'end'} >
                                                                                                    <Dropdown.Item eventKey="1">Edit Project</Dropdown.Item>
                                                                                                    {(localStorage.getItem('ksp_roles').includes('super_admin') || localStorage.getItem('ksp_roles').includes('admin')) &&
                                                                                                        <Dropdown.Item eventKey="2">Delete Project</Dropdown.Item>
                                                                                                    }
                                                                                                </Dropdown.Menu></>}
                                                                                        </div>
                                                                                    </div>
                                                                                </Dropdown>
                                                                            </td>

                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                                {projects?.data?.length === 0 && <tr key="no-data">
                                                                    <td></td>
                                                                    <td colSpan={4}>No Data Available</td>
                                                                    <td></td>
                                                                </tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div>
                                                        <select onChange={perPage} ref={pageCount}>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="200">200</option>
                                                        </select>
                                                    </div>
                                                    {projects?.data?.length &&
                                                        <Pagination>
                                                            {<Pagination.First onClick={() => projects?.first_page_url ? paginate(1) : ""} />}
                                                            {<Pagination.Prev onClick={() => projects?.prev_page_url ? paginate(projects?.current_page - 1) : ""} />}
                                                            <Pagination.Item>{projects?.from} - {projects?.to} of {projects?.total}</Pagination.Item>
                                                            {<Pagination.Next onClick={() => projects?.next_page_url ? paginate(projects?.current_page + 1) : ""} />}
                                                            {<Pagination.Last onClick={() => projects?.last_page_url ? paginate(projects?.last_page) : ""} />}
                                                        </Pagination>
                                                    }
                                                </div>
                                                <Modal show={!!modalDetails} onHide={handleClose}>
                                                    <Modal.Header className="py-3 px-4" closeButton>
                                                        <Modal.Title className="h5">{modalDetails?.title ? "Update Project" : "Create Project"}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="mb-3">
                                                                <label className="form-label">Title</label>
                                                                <Form.Control name="title" {...register('title')} isInvalid={!!errors.title} placeholder="Enter the title" />
                                                                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                                            </div>
                                                            <hr />
                                                            <div className="mb-3">
                                                                <label className="form-label">Team</label>
                                                                <Controller
                                                                    control={control}
                                                                    render={({ onInputChange, ...field }) => (
                                                                        <AsyncSelect
                                                                            {...field}
                                                                            className="w-100"
                                                                            components={{ DropdownIndicator }}
                                                                            name="team_id"
                                                                            isMulti
                                                                            openMenuOnClick={false}
                                                                            isClearable={true}
                                                                            defaultValue={((!!multiselects) && multiselects?.team_id?.length > 0) ? multiselects.team_id.map(el => ({ name: el.name, label: el.name, value: el.id })) : null}
                                                                            defaultOptions={((!!multiselects) && multiselects?.team_id?.length > 0) ? multiselects.team_id.map(el => ({ name: el.name, label: el.name, value: el.id })) : null}
                                                                            getOptionLabel={(e) => e?.label}
                                                                            getOptionValue={(e) => e?.value}
                                                                            onChange={(value) => setMultiSelects({
                                                                                ...multiselects,
                                                                                "team_id": value
                                                                            })}
                                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                                if (inputValue.trim().length > 2) {
                                                                                    const data = await axios.get(`${API_URL}/teams-search/${inputValue}`, {
                                                                                        headers: {
                                                                                            "Content-Type": "application/json",
                                                                                        },
                                                                                    })
                                                                                    callback(data.data.teams.map(el => ({ name: el.name, label: el.name, value: el.id })));
                                                                                } else {
                                                                                    callback(null);
                                                                                }
                                                                            }, 1000)}
                                                                        />
                                                                    )}
                                                                    name="team_id"
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.team_id?.message}</Form.Control.Feedback>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">DueDate</label>
                                                                <Form.Control name="due_date" type="date" {...register('due_date')} isInvalid={!!errors.due_date} />
                                                                <Form.Control.Feedback type="invalid">{errors.due_date?.message}</Form.Control.Feedback>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Internal DueDate</label>
                                                                <Form.Control name="internal_due_date" type="date" {...register('internal_due_date')} isInvalid={!!errors.internal_due_date} />
                                                                <Form.Control.Feedback type="invalid">{errors.internal_due_date?.message}</Form.Control.Feedback>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Team lead</label>
                                                                <Controller
                                                                    control={control}
                                                                    render={({ onInputChange, ...field }) => (
                                                                        <AsyncSelect
                                                                            {...field}
                                                                            className="w-100"
                                                                            components={{ DropdownIndicator }}
                                                                            name="team_lead_id" // Ensure the name matches with the register call
                                                                            isMulti
                                                                            openMenuOnClick={false}
                                                                            isClearable={true}
                                                                            defaultValue={((!!multiselects) && multiselects?.team_lead_id?.length > 0) ? { id: multiselects?.team_lead_id[0].id, value: multiselects?.team_lead_id[0].id, name: multiselects?.team_lead_id[0].label, label: multiselects?.team_lead_id[0].label } : null}
                                                                            defaultOptions={((!!multiselects) && multiselects?.team_lead_id?.length > 0) ? [multiselects.team_lead_id].map(el => { return { id: el?.id, value: el?.id, name: el?.label, label: el?.label } }) : []}
                                                                            getOptionLabel={(e) => e?.label}
                                                                            getOptionValue={(e) => e?.value}
                                                                            onChange={(value) => setMultiSelects({
                                                                                ...multiselects,
                                                                                "team_lead_id": value
                                                                            })}
                                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                                if (inputValue.trim().length > 2) {
                                                                                    const data = await axios.get(`${API_URL}/team-lead-search/${inputValue}`, {
                                                                                        headers: {
                                                                                            "Content-Type": "application/json",
                                                                                        },
                                                                                    })
                                                                                    callback(data.data.users.map(el => ({ name: el.name, label: el.name, value: el.id })));
                                                                                } else {
                                                                                    callback(null);
                                                                                }
                                                                            }, 1000)}
                                                                        />
                                                                    )}
                                                                    name="team_lead_id"
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.team_lead_id?.message}</Form.Control.Feedback>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Admin</label>
                                                                <Controller
                                                                    control={control}
                                                                    render={({ onInputChange, ...field }) => (
                                                                        <AsyncSelect
                                                                            {...field}
                                                                            className="w-100"
                                                                            components={{ DropdownIndicator }}
                                                                            name="admin_id" // Ensure the name matches with the register call
                                                                            isMulti
                                                                            openMenuOnClick={false}
                                                                            isClearable={true}
                                                                            defaultValue={((!!multiselects) && multiselects?.admin_id?.length > 0) ? { id: multiselects?.admin_id[0].id, value: multiselects?.admin_id[0].id, name: multiselects?.admin_id[0].label, label: multiselects?.admin_id[0].label } : null}
                                                                            defaultOptions={((!!multiselects) && multiselects?.admin_id?.length > 0) ? [multiselects.admin_id].map(el => { return { id: el?.id, value: el?.id, name: el?.label, label: el?.label } }) : []}
                                                                            getOptionLabel={(e) => e?.label}
                                                                            getOptionValue={(e) => e?.value}
                                                                            onChange={(value) => setMultiSelects({
                                                                                ...multiselects,
                                                                                "admin_id": value
                                                                            })}
                                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                                if (inputValue.trim().length > 2) {
                                                                                    const data = await axios.get(`${API_URL}/admin-search/${inputValue}`, {
                                                                                        headers: {
                                                                                            "Content-Type": "application/json",
                                                                                        },
                                                                                    })
                                                                                    callback(data.data.users.map(el => ({ name: el.name, label: el.name, value: el.id })));
                                                                                } else {
                                                                                    callback(null);
                                                                                }
                                                                            }, 1000)}
                                                                        />
                                                                    )}
                                                                    name="admin_id"
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.admin_id?.message}</Form.Control.Feedback>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Status</label>
                                                                <select className="form-select form-control" name="status" {...register("status")}>
                                                                    <option value={true}>Active</option>
                                                                    <option value={false}>InActive</option>
                                                                </select>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Closure</label>
                                                                <select className="form-select form-control" name="closure" {...register("closure")}>
                                                                    <option value={true}>Closed</option>
                                                                    <option value={false}>Pending</option>
                                                                </select>
                                                            </div>
                                                            <hr />
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer className="text-end">
                                                        <Button className="btn btn-light me-1" onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button className="btn btn-success" onClick={formSubmit}>
                                                            {modalDetails?.title ? 'Update' : 'Create'}
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                <Modal show={!!otherDetails} onHide={handleOtherDetailsClose}>
                                                    <Modal.Header className="py-3 px-4" closeButton>
                                                        <Modal.Title className="h5">Other Details</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="row"></div>
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">Category</label>
                                                                <Form.Control disabled={true} defaultValue={otherDetails?.category?.name} />
                                                            </div>
                                                            <hr />
                                                            <div className="mb-3">
                                                                <label className="form-label">SubCategory</label>
                                                                <Form.Control disabled={true} defaultValue={otherDetails?.sub_category?.name} />
                                                            </div>
                                                            <hr />
                                                            <div className="mb-3">
                                                                <label className="form-label">DueDate</label>
                                                                <Form.Control disabled={true} defaultValue={moment(otherDetails?.due_date).format('DD-MM-YYYY')} />
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer className="text-end">
                                                        <Button className="btn btn-light me-1" onClick={handleOtherDetailsClose}>
                                                            Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="custom-settings" role="tabpanel">
                                            Check the module and update the components
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div >
            <div className="rightbar-overlay"></div>
        </>
    )
}

export default Projects;