import actions from "./actions";

const initialState = {
    project_approvals: null,
    filterParams: {
        status: true,
        billing: "all",
        name: ""
    },
    pagination: {
        entries: 50,
        currentPage: 1
    }
};
const projectApprovalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PROJECT_APPROVALS:
            return {
                ...state,
                project_approvals: action.payload
            }
        case actions.SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.payload
            }
        case actions.SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return state;
    }
};

export default projectApprovalReducer;