import actions from "./actions";

const initialState = {
    own_tasks: null,
    own_projects: null,
    assigned_tasks: null ,
    assigned_projects: null,
    assigned_projects_by_name: [],
    assigned_tasks_by_name: [],
    collapsible_tasks_by_name: [],
    collapsible_projects_by_name: [],
};
const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_OWN_TASKS:
            return {
                ...state,
                own_tasks: action.payload
            }
        case actions.SET_OWN_PROJECTS:
            return {
                ...state,
                own_projects: action.payload
            }
        case actions.SET_ASSIGNED_TASKS:
            return {
                ...state,
                assigned_tasks: action.payload
            }
        case actions.SET_ASSIGNED_PROJECTS:
            return {
                ...state,
                assigned_projects: action.payload
            }
        case actions.SET_ASSIGNED_PROJECTS_BY_NAME:
            return {
                ...state,
                assigned_projects_by_name: action.payload
            }
        case actions.SET_ASSIGNED_TASKS_BY_NAME:
            return {
                ...state,
                assigned_tasks_by_name: action.payload
            }
        case actions.SET_COLLAPSIBLE_TASKS_BY_NAME:
            return {
                ...state,
                collapsible_tasks_by_name: action.payload
            }
        case actions.SET_COLLAPSIBLE_PROJECTS_BY_NAME:
            return {
                ...state,
                collapsible_projects_by_name: action.payload
            }
        default:
            return state;
    }
};

export default dashboardReducer;