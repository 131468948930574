import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const projectApprovalSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_PROJECT_APPROVALS, getProjectApprovals),
        yield takeEvery(actions.UPDATE_PROJECT_APPROVALS, updateProjectApprovals),
    ]);
};

const getProjectApprovals = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/project-approvals${store.getState().taskReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().taskReducer.pagination.currentPage}`}`, {
                params: {
                    status: store.getState().projectApprovalReducer.filterParams.status,
                    entries: store.getState().projectApprovalReducer.pagination.entries,
                    name: store.getState().projectApprovalReducer.filterParams.name
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({ type: actions.SET_PROJECT_APPROVALS, payload: result?.data ? result.data.project_approvals : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_PROJECT_APPROVALS, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}
const updateProjectApprovals = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.put(`${API_URL}/project-approvals`, JSON.stringify(data.payload),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
        );
        yield put({ type: actions.GET_PROJECT_APPROVALS });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.GET_PROJECT_APPROVALS });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default projectApprovalSaga;