import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Dropdown, Pagination } from "react-bootstrap";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../redux/common/actions";
import timeTrackActions from "../redux/timetrack/actions";
import settingActions from "../redux/settings/actions";
import { Popover, Tooltip } from "antd";
import Content from "../components/timetracker/content";
import successImage from './../assets/images/success.png';
import cancelImage from './../assets/images/cancel.png';
import { clear } from "@testing-library/user-event/dist/clear";

const TimeTracker = () => {

    const duplicateDescription = useRef([]);
    const duplicateFromTime = useRef([]);
    const duplicateToTime = useRef([]);
    const duplicateBillArray = useRef([]);
    const duplicateDateArray = useRef([]);
    const duplicateDiffArray = useRef([]);
    const fromTime = useRef();
    const toTime = useRef();
    const description = useRef();
    const buttonRef = useRef([]);
    const billing = useRef();
    const descArray = useRef([]);
    const billArray = useRef([]);
    const dateRef = useRef();
    const pageSelect = useRef();
    const pageCount = useRef();
    const dispatch = useDispatch();
    const [calculatedDate, setCalculatedDate] = useState('00:00:00');
    const [projectName, setProjectName] = useState(null);
    const [from, setFrom] = useState(moment(new Date()).format('HH:mm'));
    const [to, setTo] = useState(moment(new Date()).format('HH:mm'));
    const [timerMode, setTimerMode] = useState(false);
    const [timerDate, setTimerDate] = useState(null);
    const [manual, setManual] = useState(true);
    const [currentPage, setCurrentPage] = useState(null);
    var [showDuplicate, setShowDuplicate] = useState([])
    const { timeTracks, pagination, reset } = useSelector(state => state.timeTrackReducer)
    const { settings } = useSelector(state => state.settingReducer)


    var hours = "00", // obtain these values somewhere else 
        minutes = "00",
        seconds = "00",
        target = new Date(), handler;

    const selectFocus = (event) => {
        event.target.select();

    }
    const changeToTime = (e, toV) => {
        changeTime(e, toV);
        setTo(e.target.value);
        calcCommon(from, e.target.value);
    }

    const changeFromTime = (e, frm) => {
        changeTime(e, frm);
        setFrom(e.target.value);
        calcCommon(e.target.value, to);
    }

    const changeEditFromTime = (a, b, c, i, e) => {
        if (c === "description") {
            dispatch({
                type: timeTrackActions.UPDATE_TIMETRACK, payload: {
                    "id": a.id,
                    "description": b.target.value,
                    "project_id": a.task.project_id,
                    "task_id": a.task.id,
                    "start_time": a.start_time,
                    "billable": a.billable,
                    "end_time": a.end_time,
                },
                page: pageSelect?.current?.value,
                url: currentPage ?? false
            });
        }
        if (c === "timetracker") {
            dispatch({
                type: timeTrackActions.UPDATE_TIMETRACK, payload: {
                    "id": a.id,
                    "description": a.description,
                    "project_id": b.project_id,
                    "task_id": b.id,
                    "start_time": a.start_time,
                    "billable": a.billable,
                    "end_time": a.end_time
                }
            });
            buttonRef.current[e].click();
        }
        if (c === "start") {
            changeTime(b, a.start_time);
            dispatch({
                type: timeTrackActions.UPDATE_TIMETRACK, payload: {
                    "id": a.id,
                    "description": a.description,
                    "project_id": a.task.project_id,
                    "task_id": a.task.id,
                    "billable": a.billable,
                    "start_time": moment(moment(a.start_time).format('YYYY-MM-DD') + ' ' + b.target.value, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(moment(a.start_time).format('YYYY-MM-DD') + ' ' + moment(a.end_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                },
                page: pageSelect?.current?.value,
                url: currentPage ?? false
            });
        }
        if (c === "end") {
            changeTime(b, a.end_time);
            dispatch({
                type: timeTrackActions.UPDATE_TIMETRACK, payload: {
                    "id": a.id,
                    "description": a.description,
                    "project_id": a.task.project_id,
                    "task_id": a.task.id,
                    "billable": a.billable,
                    "start_time": moment(moment(a.start_time).format('YYYY-MM-DD') + ' ' + moment(a.start_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(moment(a.start_time).format('YYYY-MM-DD') + ' ' + b.target.value, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),

                },
                page: pageSelect?.current?.value,
                url: currentPage ?? false
            });
        }
        if (c === "date") {
            dispatch({
                type: timeTrackActions.UPDATE_TIMETRACK, payload: {
                    "id": a.id,
                    "description": a.description,
                    "project_id": a.task.project_id,
                    "task_id": a.task.id,
                    "billable": a.billable,
                    "start_time": moment(b.target.value + ' ' + moment(a.start_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(b.target.value + ' ' + moment(a.end_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
                },
                page: pageSelect?.current?.value,
                url: currentPage ?? false
            });
        }
    }

    const changeTime = (event, prev) => {
        let filteredInput = event.target.value;
        if (event.target.value.includes(":")) {
            filteredInput = event.target.value.replace(":", '');
        }
        if (!event.relatedTarget) {
            if (!isNaN(parseInt(filteredInput))) {
                if (filteredInput.length === 4) {
                    event.target.value = filteredInput.substring(0, 2) + ":" + filteredInput.substring(2, 4);
                    if (filteredInput.substring(0, 2) > 23) {
                        event.target.value = prev;
                    } else if (filteredInput.substring(2, 4) > 59) {
                        event.target.value = prev;
                    }
                } else if (filteredInput.length === 3) {
                    event.target.value = "0" + filteredInput.substring(0, 1) + ":" + filteredInput.substring(1, 3);
                } else if (filteredInput.length === 2) {
                    event.target.value = filteredInput.substring(0, 2) + ":00";
                } else if (filteredInput.length === 2) {
                    event.target.value = "0" + filteredInput.substring(0, 1) + ":00";
                } else {
                    event.target.value = prev;
                }
            } else {
                event.target.value = prev;
            }
        }
        if (event.target.value[2] !== ":") {
            event.target.value = event.target.value[0].toString() + event.target.value[1].toString() + ":" + event.target.value[2].toString() + event.target.value[3].toString()
        }
    }

    const startTimer = () => {
        if (!description?.current?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Description is necessary", status: false
                }
            });
            return;
        }
        if (!projectName?.project_id) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "ProjectName necessary", status: false
                }
            });
            return;
        }
        if (!projectName?.id) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Task Name necessary", status: false
                }
            });
            return;
        }
        const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
        if (moment(timerDate).format("YYYY-MM-DD") !== moment(new Date()).format("YYYY-MM-DD")) {
            dispatch({
                type: timeTrackActions.CREATE_TIMETRACK, payload: {
                    "description": description?.current?.value,
                    "billable": billing?.current?.checked,
                    "project_id": projectName.project_id,
                    "task_id": projectName.id,
                    "start_time": moment(timerDate).format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(moment(timerDate).format('YYYY-MM-DD') + ' ' + '23:59:59', 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
                }
            });
            handleTimeTrackClose();
            return;
        }
        dispatch({
            type: timeTrackActions.CREATE_TIMETRACK, payload: {
                "description": description?.current?.value,
                "billable": billing?.current?.checked,
                "project_id": projectName.project_id,
                "task_id": projectName.id,
                "start_time": moment(timerDate).format("YYYY-MM-DD HH:mm:ss"),
                "end_time": moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            }
        });
        handleTimeTrackClose();
    }

    const stopTimer = () => {
        setTimerMode(true);
        setTimerDate(new Date());
        localStorage.setItem("intial-timer", new Date());
        handler = setInterval(() => {
            var t1 = new Date(localStorage.getItem("intial-timer"));
            var t2 = new Date();
            var dif = Math.abs(t1.getTime() - t2.getTime()) / 1000;
            document.getElementById("stop-timer").innerHTML = secondsToHms(dif); // print the value
        }, 1000);
    }

    const addTimeTracker = () => {
        if (!from) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time necessary", status: false
                }
            });
        } else if (!to) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time necessary", status: false
                }
            });
        } else if (!dateRef?.current?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Date necessary", status: false
                }
            });
        } else if (!fromTime?.current?.value) {

            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time not valid.", status: false
                }
            });
        } else if (!toTime?.current?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time not valid.", status: false
                }
            });
        } else if (!moment(dateRef.current.value + ' ' + from, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time not valid.", status: false
                }
            });
        } else if (!moment(dateRef.current.value + ' ' + to, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time not valid.", status: false
                }
            });
        } else if (!projectName) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Project field neccessary.", status: false
                }
            });
        } else if (!description?.current?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Description necessary", status: false
                }
            });
        } else if (from && to && projectName && description?.current?.value &&
            moment(dateRef.current.value + ' ' + to, 'YYYY-MM-DD HH:mm:ss').isValid() &&
            moment(dateRef.current.value + ' ' + from, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: timeTrackActions.CREATE_TIMETRACK, payload: {
                    "description": description?.current?.value,
                    "billable": billing?.current?.checked,
                    "project_id": projectName.project_id,
                    "task_id": projectName.id,
                    "start_time": moment(dateRef.current.value + ' ' + from, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(dateRef.current.value + ' ' + to, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
                }
            });
        } else {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Kindly check the inputs", status: false
                }
            });
        }
    }

    const calcCommonDiff = (fr, toV) => {
        let a = fr + ":00";
        let b = toV + ":00";
        if (fr.split(':')[0] >= 12) {
            a = fr + ":00 pm";
        } else {
            a = fr + ":00 am";
        }
        if (toV.split(':')[0] >= 12) {
            b = toV + ":00 pm";
        } else {
            b = toV + ":00 am";
        }

        var startTime1 = moment(a, "HH:mm:ss a").format("HH:mm:ss a");
        var endTime1 = moment(b, "HH:mm:ss a").format("HH:mm:ss a");
        var diff = moment
            .utc(moment(endTime1, "HH:mm:ss a").diff(moment(startTime1, "HH:mm:ss a")))
            .format("HH:mm:ss");
        return diff;
    }

    const calcCommon = (fr, toV) => {
        let a = fr + ":00";
        let b = toV + ":00";
        if (fr.split(':')[0] >= 12) {
            a = fr + ":00 pm";
        } else {
            a = fr + ":00 am";
        }
        if (toV.split(':')[0] >= 12) {
            b = toV + ":00 pm";
        } else {
            b = toV + ":00 am";
        }

        var startTime1 = moment(a, 'hh:mm:ss a');
        var endTime1 = moment(b, 'hh:mm:ss a');

        if (startTime1.valueOf() > endTime1.valueOf()) {
            var diff = moment
                .utc(moment(startTime1, "HH:mm:ss a").diff(moment(endTime1, "HH:mm:ss a")))
                .format("HH:mm:ss");
            setCalculatedDate(diff);
        } else {
            var diff = moment
                .utc(moment(endTime1, "HH:mm:ss a").diff(moment(startTime1, "HH:mm:ss a")))
                .format("HH:mm:ss");
            setCalculatedDate(diff);
        }
    }

    const calcCommonEdit = (fr, toV) => {
        let a = moment(moment(fr), 'HH:mm').format('HH:mm') + ":00";
        let b = moment(moment(toV), 'HH:mm').format('HH:mm') + ":00";
        if (fr.split(':')[0] >= 12) {
            a = fr + ":00 pm";
        } else {
            a = fr + ":00 am";
        }
        if (toV.split(':')[0] >= 12) {
            b = toV + ":00 pm";
        } else {
            b = toV + ":00 am";
        }
        var startTime1 = moment(a.split(" ")[1], "HH:mm:ss a").format("HH:mm:ss a");
        var endTime1 = moment(b.split(" ")[1], "HH:mm:ss a").format("HH:mm:ss a");
        var diff = moment
            .utc(moment(endTime1, "HH:mm:ss a").diff(moment(startTime1, "HH:mm:ss a")))
            .format("HH:mm:ss");
        return diff;
    }

    useEffect(() => {
        if (localStorage.getItem('intial-timer')) {
            setTimerMode(true);
            setTimerDate(localStorage.getItem('intial-timer'));
            setManual(false);
            const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            if (localStorage.getItem('projectName')) {
                setProjectName(JSON.parse(localStorage.getItem('projectName')));
            }
            handler = setInterval(() => {
                var t1 = new Date(localStorage.getItem("intial-timer"));
                var t2 = new Date();
                if (moment(t1).format('YYYY-MM-DD') !== moment(t2).format('YYYY-MM-DD')) {
                    t2 = new Date(moment(moment(t1).format('YYYY-MM-DD') + ' ' + '23:59:59', 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"));
                    var dif = Math.abs(t1.getTime() - t2.getTime()) / 1000;
                    document.getElementById("stop-timer").innerHTML = secondsToHms(dif); // print the value
                    return;
                }
                var dif = Math.abs(t1.getTime() - t2.getTime()) / 1000;
                document.getElementById("stop-timer").innerHTML = secondsToHms(dif); // print the value
            }, 1000);
        }
    }, []);

    useEffect(() => {
        fromTime.current.value = from;
        toTime.current.value = to;
        setCurrentPage(null);
    }, []);

    useEffect(() => {
        dispatch({ type: timeTrackActions.GET_TIMETRACKS });
        dispatch({ type: settingActions.GET_SETTINGS });
    }, []);

    function datesGroupByComponent(dates) {
        if (dates) {
            return dates.map(el => moment(el).format('MM-DD-YYYY hh:mm:ss'))?.reduce((acc, date) => {

                // create a composed key: 'year-week' 
                const yearWeek = `${moment(date).year()}-${moment(date).week()}`;

                // add this key as a property to the result object
                if (!acc[yearWeek]) {
                    acc[yearWeek] = [];
                }

                // push the current date that belongs to the year-week calculated befor
                acc[yearWeek].push(date);

                return acc;

            }, {});
        } else {
            return []
        }
    }

    const duplicateTrackeList = (val) => {
        if (!duplicateDateArray?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Date necessary", status: false
                }
            });
        } else if (!duplicateFromTime?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time not valid.", status: false
                }
            });
        } else if (!duplicateToTime?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time not valid.", status: false
                }
            });
        } else if (!moment(duplicateDateArray?.current[val.id]?.value + ' ' + duplicateFromTime?.current[val.id]?.value, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "From Time not valid.", status: false
                }
            });
        } else if (!moment(duplicateDateArray?.current[val.id]?.value + ' ' + duplicateToTime?.current[val.id]?.value, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "To Time not valid.", status: false
                }
            });
        } else if (!val.project.id) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Project field neccessary.", status: false
                }
            });
        } else if (!duplicateDescription?.current[val.id]?.value) {
            dispatch({
                type: commonActions.SET_MESSAGE, payload: {
                    message: "Description necessary", status: false
                }
            });
        } else {
            dispatch({
                type: timeTrackActions.CREATE_TIMETRACK, payload: {
                    "description": duplicateDescription.current[val.id].value,
                    "project_id": val.project.id,
                    "billable": duplicateBillArray.current[val.id].checked,
                    "task_id": val.task.id,
                    "start_time": moment(moment(duplicateDateArray.current[val.id].value).format('YYYY-MM-DD') + ' ' + duplicateFromTime.current[val.id].value + ':00', 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                    "end_time": moment(moment(duplicateDateArray.current[val.id].value).format('YYYY-MM-DD') + ' ' + duplicateToTime.current[val.id].value + ':00', 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
                }
            });
        }
        // dispatch({
        //     type: timeTrackActions.CREATE_TIMETRACK, payload:
        //     {
        //         "description": duplicateDescription.current[val.id].value,
        //         "project_id": val.project_id,
        //         "billable": duplicateBillArray.current[val.id].checked,
        //         "task_id": val.task_id,
        //         "start_time": moment(val.start_time).format('YYYY-MM-DD HH:mm:ss'),
        //         "end_time": moment(val.end_time).format('YYYY-MM-DD HH:mm:ss')
        //     },
        //     page: pageSelect?.current?.value, url: currentPage ?? false
        // });
        // setShowDuplicate(showDuplicate.filter(e => e.id !== val.id));
    }

    const timeTrackAction = (e, vl) => {
        if (e === "1") {
            setShowDuplicate([...showDuplicate, {
                "id": vl.id,
                "description": vl.description,
                "project": vl.project,
                "billable": vl.billable,
                "task": vl.task,
                "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
                "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')

            }])
            // dispatch({
            //     type: timeTrackActions.CREATE_TIMETRACK, payload: {
            //         "description": vl.description,
            //         "project_id": vl.project_id,
            //         "billable": vl.billable,
            //         "task_id": vl.task.id,
            //         "start_time": moment(vl.start_time).format('YYYY-MM-DD HH:mm:ss'),
            //         "end_time": moment(vl.end_time).format('YYYY-MM-DD HH:mm:ss')
            //     },
            //     page: pageSelect?.current?.value, url: currentPage ?? false
            // });
        }
        if (e === "2") {
            dispatch({
                type: timeTrackActions.DELETE_TIMETRACK, payload: {
                    id: vl.id
                },
                page: pageSelect?.current?.value, url: currentPage ?? false
            });
        }
    }

    const perPage = (e) => {
        dispatch({
            type: timeTrackActions.SET_PAGINATION, payload: {
                currentPage: 1,
                entries: e.target.value
            }
        });
    }

    const paginate = (page) => {
        dispatch({
            type: timeTrackActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    const changeBilling = (e, a, index) => {
        billArray.current[index].checked = !a.billable;
        e.target.style.color = billArray?.current[index]?.checked ? "#0ac074" : "inherit";
        dispatch({
            type: timeTrackActions.UPDATE_TIMETRACK, payload: {
                "id": a.id,
                "description": a.description,
                "project_id": a.project_id,
                "task_id": a.task_id,
                "billable": billArray.current[index].checked,
                "start_time": moment(moment(a.start_time).format('YYYY-MM-DD') + ' ' + moment(a.start_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
                "end_time": moment(moment(a.start_time).format('YYYY-MM-DD') + ' ' + moment(a.end_time).format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
            },
            page: pageSelect?.current?.value,
            url: currentPage ?? false
        });
    }

    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h < 10 ? "0" + h : (h) : "00";
        var mDisplay = m > 0 ? m < 10 ? (":0" + m) : (":" + m) : ":00";
        var sDisplay = s > 0 ? s < 10 ? (":0" + s) : (":" + s) : ":00";
        return hDisplay + mDisplay + sDisplay;
    }

    const currentWeek = (dat) => {
        var curr = new Date();
        var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));

        //cout how much date are in database and use for loop for compare all date
        var dbdate = new Date(dat);

        if (dbdate >= firstday) {
            //this date is in current week
            return "This week"
        } else {
            //this date in outer of current week
            if (moment().diff(dat, "weeks") === 0) {
                return "Last Week"
            } else {

                let curr = new Date(dat); // get current date
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                let firstday = new Date(curr.setDate(first)).toUTCString();
                var lastday = new Date(curr.setDate(last)).toUTCString();
                return moment(moment(firstday).format('YYYY-MM-DD')).format('MMM D') + '-' + moment(moment(lastday).format('YYYY-MM-DD')).format('MMM D')
            }
        }
    }

    const handleTimeTrackClose = () => {
        hours = "00"; // obtain these values somewhere else 
        minutes = "00";
        seconds = "00";
        target = new Date(timerDate);
        target.setHours(hours);
        target.setMinutes(minutes);
        target.setSeconds(seconds);
        setTimerMode(false);
        setTimerDate(null)
        localStorage.removeItem("projectName");
        localStorage.removeItem("intial-timer");
        const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        };
        document.getElementById("stop-timer").innerHTML = "00:00:00";
    }

    const handleHide = (el) => {
        setProjectName(el);
        billing.current.checked = el.project.billable;
        if (timerMode) {
            localStorage.setItem('projectName', JSON.stringify(el));
        }
    }

    const CalculateWeeklyTime = (data) => {
        let WeekSeconds = 0;
        data?.forEach((i, index) =>
            timeTracks?.data[moment(i).format('YYYY-MM-DD')]?.forEach(td => {
                let a = calcCommonEdit(td.start_time, td.end_time).split(':');
                WeekSeconds = WeekSeconds + parseInt(a[0]) * 60 * 60;
                WeekSeconds = WeekSeconds + parseInt(a[1]) * 60;
                WeekSeconds = WeekSeconds + parseInt(a[2]);
            })
        );
        return secondsToHms(WeekSeconds);
    }

    const changeDuplicateFromTime = (e, frm, id) => {
        changeTime(e.target.value, frm);
        duplicateDiffArray.current[id].value = calcCommonDiff(e.target.value, duplicateToTime.current[id].value);
    }

    const changeDuplicateToTime = (e, to1, id) => {
        changeTime(e.target.value, to1);
        duplicateDiffArray.current[id].value = calcCommonDiff(duplicateFromTime.current[id].value, e.target.value);
    }

    function getLastSaturdayFromDate(inputDate) {
        const date = moment(inputDate);
        const daysUntilLastSaturday = (date.day()) % 7;
        const lastSaturday = date.subtract(daysUntilLastSaturday, 'days');
        return lastSaturday.format('YYYY-MM-DD');
      }
      

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: timeTrackActions.GET_TIMETRACKS });
    }, [pagination]);

    useEffect(() => {
        if (reset) {
            let ToMs = moment(moment(dateRef.current.value + ' ' + to, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")).valueOf();
            let a = calcCommonEdit(moment(dateRef.current.value + ' ' + from, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"), moment(dateRef.current.value + ' ' + to, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")).split(':');
            let seconds1 = parseInt(a[0]) * 60;
            seconds1 = seconds1 + parseInt(a[1]) * 60;
            seconds1 = seconds1 + parseInt(a[2]);
            setFrom(moment(ToMs).format('HH:mm'));
            setTo(moment(ToMs + (seconds1 * 1000) + 60000).format('HH:mm'));
            fromTime.current.value = moment(ToMs).format('HH:mm');
            toTime.current.value = moment(ToMs + (seconds1 * 1000) + 60000).format('HH:mm');
            billing.current.checked = true;
            description.current.value = null;
            dateRef.current.value = moment(new Date()).format('YYYY-MM-DD');
            setProjectName(null);
            dispatch({ type: timeTrackActions.SET_RESET, payload: false })
            setShowDuplicate([]);
        }
    }, [reset])

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered dt-responsive nowrap">
                                                <tbody>
                                                    <tr>
                                                        <td className="mw-200" width="500">
                                                            <input data-parsley-type="alphanum" type="text"
                                                                ref={description}
                                                                className="form-control" />
                                                        </td>
                                                        <td className="mw-200">
                                                            <Popover
                                                                placement="bottomLeft"
                                                                content={<Content hide={(el) => handleHide(el)} />}
                                                                title=""
                                                                trigger="click"
                                                            >
                                                                <a href="true" className="text-primary cursor-pointer blue-color">{projectName ?
                                                                    <Tooltip overlayStyle={{ maxWidth: '400px' }} title={projectName ? <div>
                                                                        <p>Project:{projectName?.projectName}</p>
                                                                        <p>Task:{projectName?.name}</p>
                                                                        <p>Client:{projectName?.clientName}</p>
                                                                    </div> : ""}>
                                                                        <span> {projectName?.projectName}:{projectName?.name} - {projectName?.clientName}</span>
                                                                    </Tooltip>


                                                                    : "Task @Project"}</a>
                                                            </Popover>
                                                        </td>
                                                        <td className="icons-wrap">
                                                            <i className="fas fa-rupee-sign" style={{ color: billing?.current?.checked ? "#0ac074" : "inherit" }} onClick={(e) => {
                                                                billing.current.checked = !billing.current.checked;
                                                                e.target.style.color = billing?.current?.checked ? "#0ac074" : "inherit";
                                                            }} ></i>
                                                            <input type="checkbox" className="d-none" ref={billing} defaultChecked={true} />

                                                        </td>
                                                        {manual &&
                                                            <>
                                                                <td className="mw-200">
                                                                    <input type="text" className="form-control wf" ref={fromTime} autoComplete="off" onBlur={(e) => changeFromTime(e, from)} onFocus={selectFocus} />
                                                                    <input type="text" className="form-control ws" ref={toTime} autoComplete="off" onBlur={(e) => changeToTime(e, to)} onFocus={selectFocus} />
                                                                </td>
                                                                <td>
                                                                    <input className="form-control" type="date" onKeyDown={(e) => e.preventDefault()} min={!localStorage.getItem('ksp_roles')?.includes('admin') ? getLastSaturdayFromDate(settings?.locked_date) : undefined} max={moment(new Date()).format('YYYY-MM-DD')} ref={dateRef} defaultValue={moment(new Date()).format('YYYY-MM-DD')} id="example-date-input" />
                                                                </td>
                                                            </>}
                                                        <td>
                                                            {timerMode ? <span id="stop-timer"></span> : <span id="start-timer">{calculatedDate}</span>}
                                                        </td>
                                                        <td>
                                                            {manual ?
                                                                <button type="button" className="btn btn-info waves-effect waves-light" onClick={addTimeTracker}>Add</button>
                                                                :
                                                                <>
                                                                    <button type="button" className={`btn ${timerMode ? 'btn-danger' : 'btn-info'} waves-effect waves-light`}
                                                                        onClick={timerMode ? startTimer : stopTimer}
                                                                    >{timerMode ? 'Stop' : 'Start'}
                                                                    </button>

                                                                </>}

                                                        </td>
                                                        <td>
                                                            {timerMode ? <span className="cursor-pointer" onClick={handleTimeTrackClose}>X</span> : <><div className="icons-wrap text-center" onClick={() => setManual(false)}><i style={{ color: manual ? "inherit" : "#0ac074" }} className="mdi mdi-clock-outline"></i></div>
                                                                <div className="icons-wrap text-center" onClick={() => setManual(true)}><i style={{ color: manual ? "#0ac074" : "inherit" }} className="mdi mdi-format-list-bulleted"></i></div></>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {timeTracks?.data ?
                                                    Object.values(datesGroupByComponent(Object.keys(timeTracks?.data)))?.map((vlD, w) =>
                                                        <div key={'week' + w} className="card-body my-3">
                                                            <div className="d-flex justify-content-between">
                                                                <p className="fw-bold">
                                                                    {currentWeek(vlD[0])}
                                                                </p>
                                                                <p className="fw-bold">{CalculateWeeklyTime(vlD)}</p>
                                                            </div>
                                                            {vlD?.map((i, index) => {
                                                                let seconds = 0;
                                                                timeTracks?.data[moment(i).format('YYYY-MM-DD')]?.forEach(td => {
                                                                    let a = calcCommonEdit(td.start_time, td.end_time).split(':');
                                                                    seconds = seconds + parseInt(a[0]) * 60 * 60;
                                                                    seconds = seconds + parseInt(a[1]) * 60;
                                                                    seconds = seconds + parseInt(a[2]);
                                                                });
                                                                return (
                                                                    <table className="table table-striped table-bordered dt-responsive nowrap mb-0" key={'day' + index + '-' + w}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th colSpan="1">{moment(i).format('MMM Do YYYY')}</th>
                                                                                <th colSpan="5"></th>
                                                                                <th colSpan="2"><span>{secondsToHms(seconds)}</span></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {timeTracks?.data[moment(i).format('YYYY-MM-DD')]?.map((vl, tm) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={'time' + tm + '-' + index + '-' + w}>
                                                                                            <td key={'description1' + w} className="mw-100">
                                                                                                <input type="text" className="form-control" disabled={(settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin'))} ref={el => descArray.current[(index + 1) * 7 + tm] = el} key={vl.description}
                                                                                                    onBlur={(e) => changeEditFromTime(vl, e, 'description')} defaultValue={vl.description} />
                                                                                            </td>
                                                                                            <td width="300">
                                                                                           {(settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin')) ?
                                                                                                 <Popover
                                                                                                    placement="bottomLeft"
                                                                                                    title=""
                                                                                                    Content={<div>Admin Users Only</div>}
                                                                                                    id="popperList"
                                                                                                    trigger="click"
                                                                                                >
                                                                                                    <>
                                                                                                        <a href="true" className="text-primary remove-btn-stl cursor-pointer blue-color"
                                                                                                            ref={el12 => buttonRef.current[w + '-' + tm + '-' + index] = el12}
                                                                                                        >{
                                                                                                                <Tooltip overlayStyle={{ maxWidth: '400px' }} title={<div>
                                                                                                                    <p>Project:{vl?.project.title}</p>
                                                                                                                    <p>Task:{vl?.task?.name}</p>
                                                                                                                    <p>Client:{vl?.project?.client?.code + '-' + vl?.project?.client?.name}</p>
                                                                                                                </div>}>
                                                                                                                    <span> {vl?.project.title}:{vl?.task?.name} - {vl?.project?.client?.code + '-' + vl?.project?.client?.name}</span>
                                                                                                                </Tooltip>}
                                                                                                        </a>
                                                                                                    </>
                                                                                                </Popover> :
                                                                                                <Popover
                                                                                                    placement="bottomLeft"
                                                                                                    content={<Content hide={(el) => changeEditFromTime(vl, el, 'timetracker', index, w + '-' + tm + '-' + index)} />}
                                                                                                    title=""
                                                                                                    id="popperList"
                                                                                                    trigger="click"
                                                                                                >
                                                                                                    <>
                                                                                                        <a href="true" className="text-primary remove-btn-stl cursor-pointer blue-color"
                                                                                                            ref={el12 => buttonRef.current[w + '-' + tm + '-' + index] = el12}
                                                                                                        >{
                                                                                                                <Tooltip overlayStyle={{ maxWidth: '400px' }} title={<div>
                                                                                                                    <p>Project:{vl?.project.title}</p>
                                                                                                                    <p>Task:{vl?.task?.name}</p>
                                                                                                                    <p>Client:{vl?.project?.client?.code + '-' + vl?.project?.client?.name}</p>
                                                                                                                </div>}>
                                                                                                                    <span> {vl?.project.title}:{vl?.task?.name} - {vl?.project?.client?.code + '-' + vl?.project?.client?.name}</span>
                                                                                                                </Tooltip>}
                                                                                                        </a>
                                                                                                    </>
                                                                                                </Popover> }
                                                                                            </td>
                                                                                            <td className="icons-wrap">
                                                                                                <i className="fas fa-rupee-sign" style={{ color: vl?.billable ? "#0ac074" : "inherit", pointerEvents: (settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin')) ? 'none' : 'inherit' }} onClick={(e) => changeBilling(e, vl, index)} ></i>
                                                                                                <input type="checkbox" className="d-none" key={vl.billable} ref={(el) => billArray.current[index] = el} defaultChecked={vl.billable} />
                                                                                            </td>
                                                                                            <td className="mw-100">
                                                                                                <input type="text" className="form-control wf" disabled={(settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin'))} onBlur={(e) => changeEditFromTime(vl, e, 'start')} onFocus={selectFocus} key={'start' + index + moment(vl.start_time).format('HH:mm')} defaultValue={moment(vl.start_time).format('HH:mm')} />
                                                                                                <input type="text" className="form-control ws" disabled={(settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin'))} onBlur={(e) => changeEditFromTime(vl, e, 'end')} onFocus={selectFocus} key={'end' + index + moment(vl.end_time).format('HH:mm')} defaultValue={moment(vl.end_time).format('HH:mm')} />
                                                                                            </td>

                                                                                            <td>
                                                                                                <input className="form-control" type="date" disabled={(settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin'))} onKeyDown={(e) => e.preventDefault()} key={moment(vl.start_time).format('YYYY-MM-DD')} defaultValue={moment(vl.start_time).format('YYYY-MM-DD')} min={!localStorage.getItem('ksp_roles')?.includes('admin') ? moment(new Date(getLastSaturdayFromDate(settings?.locked_date))).format('YYYY-MM-DD') : undefined} onChange={(e) => changeEditFromTime(vl, e, 'date')} max={moment(new Date()).format('YYYY-MM-DD')} id="example-date-input" />
                                                                                            </td>
                                                                                            <td className="mw-110">
                                                                                                <input data-parsley-type="alphanum" type="text" className="form-control" disabled key={calcCommonEdit(vl.start_time, vl.end_time)} defaultValue={calcCommonEdit(vl.start_time, vl.end_time)} />
                                                                                            </td>
                                                                                            <td colSpan={2}>
                                                                                                {(settings?.locked_date && (moment(vl.start_time).valueOf() < moment(getLastSaturdayFromDate(settings?.locked_date)).valueOf()) && !localStorage.getItem('ksp_roles')?.includes('admin'))
                                                                                                    ?
                                                                                                    <></>
                                                                                                    : <Dropdown
                                                                                                        onSelect={(e) => timeTrackAction(e, vl)}
                                                                                                    >
                                                                                                        <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                                            <div className="btn-group">
                                                                                                                <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                                                                    <a type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></a>
                                                                                                                </Dropdown.Toggle>
                                                                                                                <Dropdown.Menu align={'end'}>
                                                                                                                    <Dropdown.Item eventKey="1">Duplicate</Dropdown.Item>
                                                                                                                    <Dropdown.Item eventKey="2">Delete</Dropdown.Item>
                                                                                                                </Dropdown.Menu>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Dropdown>}
                                                                                            </td>
                                                                                        </tr>
                                                                                        {showDuplicate?.length > 0 && showDuplicate?.find(el3 => el3.id === vl.id) ?

                                                                                            <tr key={'time' + tm + '-' + index + '-' + w}>
                                                                                                <td key={'description12' + w} className="mw-100">
                                                                                                    <input type="text" className="form-control"
                                                                                                        ref={(el) => duplicateDescription.current[vl.id] = el}
                                                                                                        key={showDuplicate?.find(el3 => el3.id === vl.id).description}
                                                                                                        defaultValue={showDuplicate?.find(el3 => el3.id === vl.id).description} />
                                                                                                </td>
                                                                                                <td width="300">
                                                                                                    <Popover
                                                                                                        placement="bottomLeft"
                                                                                                        content={<Content hide={(a, b) => {
                                                                                                            let ab = showDuplicate?.find(el3 => el3.id === vl.id);
                                                                                                            setShowDuplicate([...showDuplicate.filter(vl1 => vl1.id !== vl.id), {
                                                                                                                billable: ab.billable,
                                                                                                                description: ab.description,
                                                                                                                start_time: ab.start_time,
                                                                                                                end_time: ab.end_time,
                                                                                                                project: b,
                                                                                                                task: a,
                                                                                                                id: ab.id,
                                                                                                            }])
                                                                                                        }
                                                                                                        } />}
                                                                                                        title=""
                                                                                                        id="popperList"
                                                                                                        trigger="click"
                                                                                                    >
                                                                                                        <>
                                                                                                            <a href="true" className="text-primary remove-btn-stl cursor-pointer blue-color"
                                                                                                                ref={el12 => buttonRef.current[w + '-' + tm + '-' + index] = el12}
                                                                                                            >{
                                                                                                                    <Tooltip overlayStyle={{ maxWidth: '400px' }} title={<div>
                                                                                                                        <p>Project:{showDuplicate?.find(el3 => el3.id === vl.id)?.project.title}</p>
                                                                                                                        <p>Task:{showDuplicate?.find(el3 => el3.id === vl.id)?.task?.name}</p>
                                                                                                                        <p>Client:{showDuplicate?.find(el3 => el3.id === vl.id)?.project?.client?.code + '-' + showDuplicate?.find(el3 => el3.id === vl.id)?.project?.client?.name}</p>
                                                                                                                    </div>}>
                                                                                                                        <span> {showDuplicate?.find(el3 => el3.id === vl.id)?.project.title}:{showDuplicate?.find(el3 => el3.id === vl.id)?.task?.name} - {showDuplicate?.find(el3 => el3.id === vl.id)?.project?.client?.code + '-' + showDuplicate?.find(el3 => el3.id === vl.id)?.project?.client.name}</span>
                                                                                                                    </Tooltip>}
                                                                                                            </a>
                                                                                                        </>
                                                                                                    </Popover>
                                                                                                </td>
                                                                                                <td className="icons-wrap">
                                                                                                    <i className="fas fa-rupee-sign" style={{ color: showDuplicate?.find(el3 => el3.id === vl.id)?.billable ? "#0ac074" : "inherit" }} onClick={() => setShowDuplicate(showDuplicate.map(el123 => el123.id === vl.id ? {
                                                                                                        ...el123, billable: !duplicateBillArray.current[vl.id].checked
                                                                                                    } : el123))}></i>
                                                                                                    <input type="checkbox" ref={(el123) => duplicateBillArray.current[vl.id] = el123} className="d-none" key={showDuplicate?.find(el3 => el3.id === vl.id).billable} defaultChecked={showDuplicate?.find(el3 => el3.id === vl.id).billable} />
                                                                                                </td>
                                                                                                <td className="mw-100">
                                                                                                    <input type="text" className="form-control wf" ref={(el) => duplicateFromTime.current[vl.id] = el} onFocus={selectFocus} onBlur={(e) => changeDuplicateFromTime(e, moment(showDuplicate?.find(el3 => el3.id === vl.id).start_time).format('HH:mm'), vl.id)} key={'start123' + index + moment(showDuplicate?.find(el3 => el3.id === vl.id).start_time).format('HH:mm')} defaultValue={moment(showDuplicate?.find(el3 => el3.id === vl.id).start_time).format('HH:mm')} />
                                                                                                    <input type="text" className="form-control ws" ref={(el) => duplicateToTime.current[vl.id] = el} onFocus={selectFocus} onBlur={(e) => changeDuplicateToTime(e, moment(showDuplicate?.find(el3 => el3.id === vl.id).end_time).format('HH:mm'), vl.id)} key={'end123' + index + moment(showDuplicate?.find(el3 => el3.id === vl.id).end_time).format('HH:mm')} defaultValue={moment(showDuplicate?.find(el3 => el3.id === vl.id).end_time).format('HH:mm')} />
                                                                                                </td>

                                                                                                <td>
                                                                                                    <input ref={(el) => duplicateDateArray.current[vl.id] = el} className="form-control" type="date" onKeyDown={(e) => e.preventDefault()} key={moment(showDuplicate?.find(el3 => el3.id === vl.id).start_time).format('YYYY-MM-DD')} defaultValue={moment(showDuplicate?.find(el3 => el3.id === vl.id).start_time).format('YYYY-MM-DD')} min={!localStorage.getItem('ksp_roles')?.includes('admin') ? moment(new Date(getLastSaturdayFromDate(settings?.locked_date))).format('YYYY-MM-DD') : undefined} max={moment(new Date()).format('YYYY-MM-DD')} id="example-date-input" />
                                                                                                </td>
                                                                                                <td className="mw-110">
                                                                                                    <input data-parsley-type="alphanum" disabled={true} ref={(el) => duplicateDiffArray.current[vl.id] = el} type="text" className="form-control" key={'duplicates' + vl.id} defaultValue={calcCommonEdit(showDuplicate?.find(el3 => el3.id === vl.id).start_time, showDuplicate?.find(el3 => el3.id === vl.id).end_time)} />
                                                                                                </td>
                                                                                                <td colSpan={2}>
                                                                                                    <Dropdown
                                                                                                    >
                                                                                                        <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                                                            <div className="duplicateIcons">
                                                                                                                <img src={successImage} alt="successTick" onClick={() => duplicateTrackeList(showDuplicate?.find(el3 => el3.id === vl.id))} />
                                                                                                                <img src={cancelImage} alt="cancelTick" onClick={() => {
                                                                                                                    setShowDuplicate(showDuplicate.filter((e) => e.id !== vl.id))
                                                                                                                }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Dropdown>
                                                                                                </td>
                                                                                            </tr >


                                                                                            : <></>
                                                                                        }
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                    :
                                                    <></>}
                                            </div>
                                            <div>
                                                <select onChange={perPage}>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                </select>
                                            </div>
                                            {timeTracks?.total > 0 &&
                                                <Pagination>
                                                    {<Pagination.First onClick={() => timeTracks?.first_page_url ? paginate(1) : ""} />}
                                                    {<Pagination.Prev onClick={() => timeTracks?.prev_page_url ? paginate(timeTracks?.current_page - 1) : ""} />}
                                                    <Pagination.Item>{timeTracks?.from} - {timeTracks?.to} of {timeTracks?.total}</Pagination.Item>
                                                    {<Pagination.Next onClick={() => timeTracks?.next_page_url ? paginate(timeTracks?.current_page + 1) : ""} />}
                                                    {<Pagination.Last onClick={() => timeTracks?.last_page_url ? paginate(timeTracks?.last_page) : ""} />}
                                                </Pagination>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div >
            <div className="rightbar-overlay"></div>

        </>
    )
}

export default TimeTracker;