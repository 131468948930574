import commonActions from "../redux/common/actions";
import { store } from "../redux/store";
const handleError = (res) => {
    store.dispatch({ type: commonActions.SET_LOADER, payload: false });
    try {
        if (res?.status) {
            switch (res.status) {
                case 401:
                    if (res.data.message) {
                        return res.data.message
                    } else {
                        return res.data.error
                    }
                case 403:
                    return res?.data?.message
                case 404:
                    return res?.data?.message ? res?.data?.message : "Data Not Found"
                case 422:
                    return res?.data?.message ? res?.data?.message : Object.values(res.data.errors)[0][0];
                case 500:
                    return "Server error Occured";
                default:
                    return "Server error Occured";
            }
        } else {
            return "Server error Occured";
        }
    } catch {
        return "Server error Occured";
    }
};

export default handleError;