import './App.css';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from './redux/store';
import Login from './containers/auth/login';
import Reports from './containers/reports';
import Category from './containers/category';
import SubCategory from './containers/sub-category';
import Clients from './containers/clients';
import Projects from './containers/projects';
import Settings from './containers/settings';
import Team from './containers/team';
import TimeTracker from './containers/timetraker';
import setAuthToken from './utils/setAuthToken';
import './assets/css/app.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icons.min.css';
import './assets/css/style.css';
import Loader from './components/common/loader';
import Message from './components/common/message';
import { PublicRoute } from './components/routes/PublicRoute';
import { PrivateRoute } from './components/routes/PrivateRoute';
import Profile from './containers/profile';
import ProjectSettings from './containers/project-settings';
import AddProject from './containers/add-project';
import Invitaion from './containers/auth/invitation';
import Tasks from './containers/tasks';
import OTP from './containers/auth/otp';
import ConfirmPassword from './containers/auth/confirm-password';
import ForgotPassword from './containers/auth/forgot-password';
import ProjectDetail from './containers/project-detail';
import Dashboard from './containers/dashboard';
import ProjectApprovals from './containers/project-approval';

function App() {

  if (localStorage.getItem('ksp_token')) {
    setAuthToken(localStorage.getItem('ksp_token'));
  } else {
    setAuthToken(null);
  }

  return (
    <>
      <Provider store={store} >
        <>
          <Loader />
          <Message />
          <BrowserRouter >
            <Routes>

              <Route element={<PublicRoute />}>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/otp" element={<OTP />} />
                <Route exact path="/confirm-password" element={<ConfirmPassword />} />
                <Route exact path="/" element={<Login />} />
                <Route exact path="/invitation/:slug" element={<Invitaion />} />
              </Route>

              <Route element={<PrivateRoute />}>
                <Route exact path="/timetracker" element={<TimeTracker />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/create-project" element={<AddProject />} />
                <Route exact path="/projects" element={<Projects />} />
                {(localStorage.getItem('ksp_roles')?.includes('super_admin') || localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('team_lead')) &&
                  <>
                    <Route exact path="/reports" element={<Reports />} />
                    <Route exact path="/projects/:slug/detail" element={<ProjectDetail />} />
                  </>
                }
                 <Route exact path="/projects/:slug/detail" element={<ProjectDetail />} />
                {(localStorage.getItem('ksp_roles')?.includes('admin') || localStorage.getItem('ksp_roles')?.includes('super_admin')) && <>
                  <Route exact path="/team" element={<Team />} />
                  <Route exact path="/clients" element={<Clients />} />
                  <Route exact path="/settings" element={<Settings />} />
                  <Route exact path="/project-settings" element={<ProjectSettings />} />
                  <Route exact path="/categories" element={<Category />} />
                  <Route exact path="/sub-categories" element={<SubCategory />} />
                  <Route exact path="/project-approvals" element={<ProjectApprovals />} />
                </>}
              </Route>
            </Routes>
          </BrowserRouter>
        </>
      </Provider>
    </>
  );
}

export default App;
