import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Pagination } from "react-bootstrap";
import Header from "../components/common/header";
import Sidebar from "../components/common/sidebar";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import Footer from "../components/common/footer";
import { debounce } from "lodash";
import projectApprovalActions from "../redux/project-approval/actions";

const ProjectApprovals = () => {

    const dispatch = useDispatch();
    const { project_approvals, filterParams, pagination } = useSelector(state => state.projectApprovalReducer);

    const updateApproval = (projectId) => {
        dispatch({
            type: projectApprovalActions.UPDATE_PROJECT_APPROVALS, payload: {
                project_id: projectId
            }
        });
    }

    const paginate = (page) => {
        dispatch({
            type: projectApprovalActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    useEffect(() => {
        dispatch({ type: projectApprovalActions.GET_PROJECT_APPROVALS });
    }, []);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: projectApprovalActions.GET_PROJECT_APPROVALS });
    }, [filterParams, pagination]);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane active" id="custom-home" role="tabpanel">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                </div>
                                                                <div className="col-md-6">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-3">
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-30">
                                                        <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Project</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {project_approvals?.data?.map(el => {
                                                                    return (
                                                                        <tr>
                                                                            <td width="300">
                                                                                <h6>{el.project.title}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                <h6>{el.status ? 'ACTIVE' : 'INACTIVE'}</h6>
                                                                            </td>
                                                                            <td width="300">
                                                                                {el.status ?
                                                                                    <Button className="btn btn-primary" onClick={() => updateApproval(el.project_id)}>
                                                                                        Approve
                                                                                    </Button> : <Button className="btn btn-danger" disabled >
                                                                                        Approved
                                                                                    </Button>}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                                {project_approvals?.data?.length === 0 && <tr key="no-data">
                                                                    <td></td>
                                                                    <td colSpan={2}>No Data Available</td>
                                                                    <td></td>
                                                                </tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {project_approvals?.data?.length &&
                                                        <Pagination>
                                                            {<Pagination.First onClick={() => project_approvals?.first_page_url ? paginate(1) : ""} />}
                                                            {<Pagination.Prev onClick={() => project_approvals?.prev_page_url ? paginate(project_approvals?.current_page - 1) : ""} />}
                                                            <Pagination.Item>{project_approvals?.from} - {project_approvals?.to} of {project_approvals?.total}</Pagination.Item>
                                                            {<Pagination.Next onClick={() => project_approvals?.next_page_url ? paginate(project_approvals?.current_page + 1) : ""} />}
                                                            {<Pagination.Last onClick={() => project_approvals?.last_page_url ? paginate(project_approvals?.last_page) : ""} />}
                                                        </Pagination>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="custom-settings" role="tabpanel">
                                            Check the module and update the components
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div >
                <div className="rightbar-overlay"></div>
            </div>
        </>
    )
}

export default ProjectApprovals;
