import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import taskActions from "../redux/tasks/actions";
import Button from 'react-bootstrap/Button';
import { Form, Dropdown, Pagination } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AsyncSelect from 'react-select/async';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import commonActions from "../redux/common/actions";
import { debounce } from "lodash";
import { DropdownIndicator } from "../utils/helpers";
import { API_URL } from "../utils/constant";
import axios from "axios";
import moment from 'moment';
const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    status: Yup.boolean().required()
});

const style = {
    control: base => ({
        ...base,
        border: "1px solid #e8ebf3",
        padding: "7px 0px",
        ':hover': {
            borderColor: "#e8ebf3",
        },
        boxShadow: "none"
    }),
};
const Tasks = () => {

    const dispatch = useDispatch();
    const [modalDetails, setModalDetails] = useState(false);
    const [modalUserDetail, setModalUserDetail] = useState(false);
    // const [modalDeleteDetails, setModalDeleteDetails] = useState(false);
    const { tasks, filterParams, pagination } = useSelector(state => state.taskReducer);
    const form = useRef();
    const taskSelect = useRef();
    const taskName = useRef();
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });
    const projectId = window.location.pathname.split('/')[2];

    const handleClose = () => {
        setModalDetails(null);
        setModalUserDetail(null);
        reset({
            'name': '',
            'status': true,
            'description': '',
            'task_due_date': moment(new Date()).format('YYYY-MM-DD'),
        })
    }

    const handleAddOpen = () => {
        setModalDetails(true);
    }

    const handleEditOpen = (e, b) => {
        if (e === "1") {
            reset({
                name: b.name,
                status: b.status,
                completed: !!b?.completed ? true : false,
                type: b?.type,
                description: b?.description,
                task_due_date: moment(b?.task_due_date).format('YYYY-MM-DD'),
            });
            setModalDetails(b);
            setModalUserDetail(b?.user);
        } else {
            // setModalDeleteDetails(b);
            dispatch({
                type: taskActions.DELETE_TASK, payload: { id: b?.id }
            });
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    const onSubmit = (data) => {
        if (!!modalDetails?.name) {
            data['id'] = modalDetails?.id;
            data['project_id'] = projectId;
            data['task_due_date'] = data.task_due_date;
            data['description'] = data.description;
            data['user_id'] = data?.user?.value ? data?.user?.value : modalUserDetail?.id;
            data['completed'] = data.completed.toString() == "true" ? true: false;
            data['name'] = data.name;
            data['status'] = data.status.toString() == "true" ? true: false;
            dispatch({
                type: taskActions.UPDATE_TASK, payload: data,
            });
        } else {
            data['project_id'] = projectId;
            data['user_id'] = data?.user?.value;
            data['task_due_date'] = data.task_due_date;
            data['name'] = data.name;
            data['description'] = data.description;
            data['status'] = data.status.toString() == "true" ? true: false;
            dispatch({
                type: taskActions.CREATE_TASK, payload: data
            });
        }
    };

    const paginate = (page) => {
        dispatch({
            type: taskActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: page
            }
        });
    }

    const handleStatus = (e) => {
        dispatch({
            type: taskActions.SET_FILTER_PARAMS, payload: {
                name: taskName.current.value,
                status: e.target.value
            }
        });
        dispatch({
            type: taskActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: 1
            }
        });
    }

    const handleName = (e) => {
        dispatch({
            type: taskActions.SET_FILTER_PARAMS, payload: {
                status: taskSelect.current.value,
                name: e.target.value
            }
        });
        dispatch({
            type: taskActions.SET_PAGINATION, payload: {
                ...pagination,
                currentPage: 1
            }
        });
    }

    const debouncedChangeHandler = useCallback(
        debounce(handleName, 400)
        , []);

    useEffect(() => {
        handleClose();
    }, [tasks]);

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: taskActions.GET_TASKS });
    }, [filterParams, pagination]);

    return (
        <>
            <div className="tab-content p-3">
                <div className="tab-pane active" id="custom-home" role="tabpanel">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <select className="form-select" ref={taskSelect} onChange={handleStatus}>
                                                <option value={true}>Show Active</option>
                                                <option value={false}>Show Archive</option>
                                                <option value={"all"}>Show All</option>
                                                <option value={"all_tasks"}>Show All Tasks</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <form className="app-search client-search">
                                                <div className="position-relative">
                                                    <input type="text" onChange={debouncedChangeHandler} className="form-control" ref={taskName} placeholder="Search by name" />
                                                    <span className="ri-search-line"></span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-outline-info waves-effect waves-light w-100" data-bs-toggle="modal" onClick={handleAddOpen}>Add Task</button>
                                </div>
                            </div>
                            <div className="table-responsive mt-30">
                                <table className="table table-striped table-bordered dt-responsive nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks?.data?.map(el => {
                                            return (
                                                <tr>
                                                    <td width="300">
                                                        <h6>{el.name}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{el.type}</h6>
                                                    </td>
                                                    <td width="300">
                                                        <h6>{el.status ? "Active" : "InActive"}</h6>
                                                    </td>
                                                    <td width="50">
                                                        <Dropdown onSelect={(e) => handleEditOpen(e, el)}>
                                                            <div className="btn-toolbar justify-content-md-end" role="toolbar">
                                                                <div className="btn-group">
                                                                    <Dropdown.Toggle variant="white" split id="dropdown-split-basic" className="btn dropdown-toggle">
                                                                        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-dots-vertical ms-1"></i></button>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu align={'end'} >
                                                                        <Dropdown.Item eventKey="1">Edit Task</Dropdown.Item>
                                                                        <Dropdown.Item eventKey="2">Delete Task</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </div>
                                                            </div>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                        {tasks?.data?.length === 0 && <tr key="no-data">
                                            <td></td>
                                            <td colSpan={2}>No Data Available</td>
                                            <td></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {tasks?.data?.length &&
                                <Pagination>
                                    {<Pagination.First onClick={() => tasks?.first_page_url ? paginate(1) : ""} />}
                                    {<Pagination.Prev onClick={() => tasks?.prev_page_url ? paginate(tasks?.current_page - 1) : ""} />}
                                    <Pagination.Item>{tasks?.from} - {tasks?.to} of {tasks?.total}</Pagination.Item>
                                    {<Pagination.Next onClick={() => tasks?.next_page_url ? paginate(tasks?.current_page + 1) : ""} />}
                                    {<Pagination.Last onClick={() => tasks?.last_page_url ? paginate(tasks?.last_page) : ""} />}
                                </Pagination>
                            }
                        </div>
                        <Modal show={!!modalDetails} onHide={handleClose}>
                            <Modal.Header className="py-3 px-4" closeButton>
                                <Modal.Title className="h5">{modalDetails?.name ? "Update Task" : "Create Task"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row"></div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <Form.Control name="name" {...register('name')} isInvalid={!!errors.name} placeholder="Enter the name" />
                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        <div className="mb-3">
                                            <label className="form-label">Description</label>
                                            <Form.Control name="description" {...register('description')} isInvalid={!!errors.name} placeholder="Enter the description" />
                                            <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        <div className="mb-3">
                                            <label className="form-label">Due Date</label>
                                            <Form.Control name="description" type="date" {...register('task_due_date')} isInvalid={!!errors.task_due_date} />
                                            <Form.Control.Feedback type="invalid">{errors.task_due_date?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        {((localStorage.getItem('ksp_roles')?.includes('admin')) || (localStorage.getItem('ksp_roles')?.includes('team_lead'))) && 
                                        <>
                                        <div className="mb-3">
                                            <label className="form-label">Users</label>
                                            <Controller
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <AsyncSelect
                                                            styles={style}
                                                            {...field}
                                                            className="w-100"
                                                            inputRef={field.ref}
                                                            components={{ DropdownIndicator }}
                                                            name={"user"}
                                                            openMenuOnClick={false}
                                                            isClearable={true}
                                                            defaultValue={(!!modalUserDetail) ? { id: modalUserDetail?.id, value: modalUserDetail?.id, name:modalUserDetail?.name, label: modalUserDetail?.name } :null}
                                                            defaultOptions={(!!modalUserDetail) ? [modalUserDetail].map(el => { return { id: el?.id, value: el?.id, name:el?.name, label: el?.name } }) : []}
                                                            getOptionLabel={(e) => e?.label}
                                                            getOptionValue={(e) => e?.value}
                                                            // onChange={(e) => setModalUserDetail(e)}
                                                            loadOptions={debounce(async (inputValue, callback) => {
                                                                if (inputValue.trim().length > 2) {
                                                                    const data = await axios.get(`${API_URL}/staff-search/${inputValue}`,
                                                                        {
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                        })
                                                                    callback(data.data.users.map(el => { return { name: el.name, label: el.name, value: el.id } }));
                                                                } else {
                                                                    callback(null);
                                                                }
                                                            }, 1000)}                                                                                            // onInputChange={(e) => handleSelectChange(e)}
                                                        />
                                                    );
                                                }}
                                                name="user"
                                            />
                                            <Form.Control.Feedback type=" invalid">{errors.user?.message}</Form.Control.Feedback>
                                        </div>
                                        <hr />
                                        </>}
                                        <hr />
                                        {modalDetails?.name ? <>
                                        <div className="mb-3">
                                            <label className="form-label">Completed</label>
                                            <select className="form-select form-control" name="completed" {...register("completed")}>
                                                <option value={true}>Active</option>
                                                <option value={false}>InActive</option>
                                            </select>
                                        </div>
                                        <hr />
                                       </> : <></>}
                                        <div className="mb-3">
                                            <label className="form-label">Status</label>
                                            <select className="form-select form-control" name="status" {...register("status")}>
                                                <option value={true}>Active</option>
                                                <option value={false}>InActive</option>
                                            </select>
                                        </div>
                                        <hr />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="text-end">
                                <Button className="btn btn-light me-1" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button className="btn btn-success" onClick={formSubmit}>
                                    {modalDetails?.name ? 'Update' : 'Create'}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                <div className="tab-pane" id="custom-settings" role="tabpanel">
                    Check the module and update the components
                </div>
            </div>
        </>
    )
}

export default Tasks;