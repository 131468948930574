import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import handleError from '../../utils/errors';
import { store } from '../store';

const projectHistorySaga = function* () {
    yield all([
        yield takeEvery(actions.GET_PROJECT_HISTORIES, getProjectHistories),
    ]);
};

const getProjectHistories = function* (data) {
    try {
        yield put({ type: commonActions.SET_LOADER, payload: true });
        const result = yield call(() =>
            axios.get(`${API_URL}/project-histories/${window.location.pathname.split('/')[2]}${store.getState().taskReducer.pagination.currentPage === 1 ? "" : `?page=${store.getState().taskReducer.pagination.currentPage}`}`, {
                params: {
                    status: store.getState().projectHistoryReducer.filterParams.status,
                    entries: store.getState().projectHistoryReducer.pagination.entries,
                    name: store.getState().projectHistoryReducer.filterParams.name
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }));
        yield put({ type: actions.SET_PROJECT_HISTORIES, payload: result?.data ? result.data.project_histories : null });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_PROJECT_HISTORIES, payload: null });
        yield put({
            type: commonActions.SET_MESSAGE, payload: {
                message: handleError(err?.response), status: false
            }
        });
    }
}


export default projectHistorySaga;